import React from "react";
import "./index.css";
import {
  PhoneOutlined,
  MailOutlined,
  EnvironmentOutlined,
} from "@ant-design/icons";
import { PRIMARY_COLOR } from "../../../Utils/Urls";

export default function Items(props: any) {
  const { p, title, sub, sub1, title2 } = props;
  return (
    <div className="items-cart">
      <h3>{title}</h3>
      <h2>{title2}</h2>
      <p style={{ color: "gray" }}>
        {p ? <EnvironmentOutlined style={{ color: PRIMARY_COLOR }} /> : null} {p}
      </p>
      <h4 style={{ color: PRIMARY_COLOR }}>
        {" "}
        {sub ? <PhoneOutlined style={{ color: PRIMARY_COLOR }} /> : null}
        {sub}
      </h4>
      <h4 style={{ color: "gray" }}>
        {sub1 ? <MailOutlined style={{ color: "gray" }} /> : null} {sub1}
      </h4>
    </div>
  );
}
