import React, { useEffect, useState } from "react";
import "./index.css";
import {
  message,
  Modal,
  Input,
  Tooltip,
  Upload,
  Switch,
  Button,
  InputNumber,
  Select,
  Tag,
} from "antd";
import { PlusCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { mutations } from "../../GraphQL";
import { useMutation, Mutation } from "react-apollo";
import { DeleteOutlined } from "@ant-design/icons";
import { LOCAL_API_URL } from "../../config";
import Alergenos from "./alergenos";
import i18n from "../../i18n";
import { PRIMARY_COLOR } from "../../Utils/Urls";

const { TextArea } = Input;

function getBase64(file: any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

const options = [{ value: "Delivery" }, { value: "Local" }];

export default function Add(props: any) {
  const { store, refetch, bundled, fromList } = props;
  const [visible, setVisible] = useState(false);
  const [isNew, setisNew] = useState(false);
  const [isPopular, setisPopular] = useState(false);
  const [isOffert, setisOffert] = useState(false);
  const [title, settitle] = useState("");
  const [ingredientes, setingredientes] = useState("");
  const [imagen, setimagen] = useState("");
  const [loadingImage, setloadingImage] = useState(false);
  const [price, setprice] = useState(0);
  const [previus_Price, setprevius_Price] = useState(0);
  const [priceLocal, setpriceLocal] = useState(0);
  const [previus_PriceLocal, setprevius_PriceLocal] = useState(0);
  const [priceLocal1, setpriceLocal1] = useState(0);
  const [previus_PriceLocal1, setprevius_PriceLocal1] = useState(0);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [recomended, setrecomended] = useState(false);
  const [snoozed, setsnoozed] = useState(false);
  const [locationType, setLocationType] = useState(["Delivery"]);
  const [alergenos, setAlergenos] = useState<any>([]);
  const [alergenosBackEnd, setAlergenosBackend] = useState<any>([]);
  const [label, setlabel] = useState("");
  const [label1, setlabel1] = useState("");
  const domain = localStorage.getItem("domain");

  const [createProduct] = useMutation(mutations.CREATE_PRODUCT);

  const idInLocal = locationType.filter((x) => x === "Local").length > 0;
  const idInDelivery = locationType.filter((x) => x === "Delivery").length > 0;

  const uploadButton = (
    <Tooltip title={i18n.t('addProduct:addPhoto')}>
      <div style={{ height: 230, display: "grid", alignItems: "center" }}>
        <div className="ant-upload-text">
          {loadingImage ? (
            <LoadingOutlined style={{ fontSize: 30, color: PRIMARY_COLOR }} />
          ) : (
            <PlusCircleOutlined style={{ fontSize: 30, color: PRIMARY_COLOR }} />
          )}
          <p>{i18n.t('addProduct:addPhoto')}</p>
        </div>
      </div>
    </Tooltip>
  );

  const tagRender = (props: any) => {
    const { label, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color="green"
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  };

  const getAlergenos = async () => {
    const resp = await fetch(`${LOCAL_API_URL}/alergenos`);
    const data = await resp.json();
    if (data.success) {
      setAlergenosBackend(data.data);
    } else {
      setAlergenosBackend([]);
    }
  };

  useEffect(()=> {
    getAlergenos()
  }, [])

  useEffect(()=> {
    getAlergenos()
  }, locationType)

 

  const showModal = () => {
    setVisible(true);
  };

  const input = {
    domain: domain,
    language: i18n.language,
    name: title,
    description: ingredientes,
    account: store,
    location: store,
    productType: 1,
    plu: "",
    sortOrder: 0,
    deliveryTax: 0,
    takeawayTax: 0,
    multiply: 1,
    multiMax: 1,
    posProductId: "",
    posProductCategoryId: [],
    subProducts: [],
    productTags: [],
    posCategoryIds: [],
    imageUrl: imagen,
    max: 1,
    min: 1,
    capacityUsages: [],
    parentId: "",
    visible: true,
    snoozed: snoozed,
    subProductSortOrder: [],
    recomended: recomended,
    quantity: 1,
    new: isNew,
    popular: isPopular,
    offert: isOffert,
    storeId: store,
    isBundled: bundled,
    locationType: locationType,
    alergenos: alergenos,
    priceInHouse: Number(priceLocal.toFixed(0)),
    previous_priceInHouse: Number(previus_PriceLocal.toFixed(0)),
    previous_price: Number(previus_Price.toFixed(0)),
    price: Number(price.toFixed(0)),
    priceInHouse1: Number(priceLocal1.toFixed(0)),
    previous_priceInHouse1: Number(previus_PriceLocal1.toFixed(0)),
    label: label,
    label1: label1
  };

  const handleOk = () => {
    if(price < 0) {
      message.warning(i18n.t('addProduct:priceRequired'))
      return
    }
    setConfirmLoading(true);
    createProduct({ variables: { input: { data: input, domain: domain, language: i18n.language } } })
      .then((res) => {
        if (res.data.createProduct.success) {
          message.success(i18n.t('addProduct:productSuccessfullyAdded'));
          refetch();
          setConfirmLoading(false);
          setVisible(false);
        } else {
          message.warning(i18n.t('addProduct:somethingWentWrong'));
          refetch();
          setConfirmLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
        message.error(i18n.t('addProduct:somethingWentWrong'));
        refetch();
        setConfirmLoading(false);
      });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  function onChangeRecomended(checked: boolean) {
    setrecomended(checked);
  }

  function onChangeSnoozed(checked: boolean) {
    setsnoozed(checked);
  }

  function onChangeNew(checked: boolean) {
    setisNew(checked);
  }

  function onChangePopular(checked: boolean) {
    setisPopular(checked);
  }

  function onChangeOffert(checked: boolean) {
    setisOffert(checked);
  }

  const isOk = () => {
    if (title && locationType.length > 0) {
      return false;
    } else {
      return true;
    }
  };

  const isOkLocal = () => {
    if (title && locationType.length > 0) {
      if (idInDelivery) {
        return false;
      } else if (!idInDelivery) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const onchangeTitle = (e: any) => {
    e.preventDefault();
    settitle(e.target.value);
  };

  const onchangeIngre = (e: any) => {
    e.preventDefault();
    setingredientes(e.target.value);
  };

  return (
    <>
      <Button
        onClick={() => showModal()}
        type={bundled && !fromList ? "dashed" : "primary"}
        icon={<PlusCircleOutlined />}
        style={{
          height: bundled && !fromList ? 32 : 40,
          marginTop: bundled && !fromList ? 10 : 0,
        }}
      >
        {bundled ? i18n.t('addProduct:createOption') : i18n.t('addProduct:addProduct')}
      </Button>

      <Modal
        title={i18n.t('addProduct:addProduct')}
        open={visible}
        onOk={handleOk}
        okText={i18n.t('addProduct:addProduct')}
        cancelText={i18n.t('addProduct:cancel')}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        okButtonProps={{
          disabled: bundled
            ? title
              ? false
              : true
            : idInLocal
            ? isOkLocal()
            : isOk(),
        }}
      >
        <div className="content_modal">
          <p>{i18n.t('addProduct:productPhoto')}</p>
          <Mutation mutation={mutations.UPLOAD_FILE_AWS}>
            {(singleUploadToAws: any) => (
              <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                customRequest={async (data) => {
                  setloadingImage(true);
                  let imgBlob = await getBase64(data.file);
                  singleUploadToAws({ variables: { imgBlob, domain: domain, language: i18n.language } })
                    .then((res: any) => {
                      setloadingImage(false);
                      setimagen(res.data.singleUploadToAws.data.Location);
                    })
                    .catch((error: any) => {
                      setloadingImage(false);
                      message.warning(
                        i18n.t('addProduct:imageTooLarge')
                      );
                    });
                }}
              >
                {imagen ? (
                  <Tooltip title={i18n.t('addProduct:clickToChange')}>
                    <img className="imagen_prod_add" alt="" src={imagen} />
                  </Tooltip>
                ) : null}

                {!imagen ? uploadButton : null}
              </Upload>
            )}
          </Mutation>

          {imagen ? (
            <Button
              onClick={() => setimagen("")}
              icon={<DeleteOutlined />}
              type="primary"
              danger
              style={{ width: 200, marginTop: 10 }}
            >
              {i18n.t('addProduct:deleteImage')}
            </Button>
          ) : null}
          <br />

          <Button
            type="primary"
            style={{ width: 200, marginTop: 10 }}
            href="https://www.iloveimg.com/es/comprimir-imagen"
            target="_blank"
          >
            {i18n.t('addProduct:compressImage')}
          </Button>

          <div className="add_info">
            <h4>{i18n.t('addProduct:productInfo')}</h4>
            <p>
              {i18n.t('addProduct:productName')} <span style={{ color: "red" }}>*</span>
            </p>
            <Input placeholder={i18n.t('addProduct:productName')} onChange={onchangeTitle} />
            <br />
            <br />
            {!bundled ? (
              <>
                <p>{i18n.t('addProduct:ingredientsOrDescription')}</p>
                <TextArea
                  rows={4}
                  // showCount
                  // maxLength={300}
                  onChange={onchangeIngre}
                  placeholder={i18n.t('addProduct:ingredientsOrDescriptionProduct')}
                />
              </>
            ) : null}

            {idInDelivery ? (
              <div className="btn_contents">
                <div style={{ width: "100%" }}>
                  <p>
                    {i18n.t('addProduct:salePrice')}{" "}
                    {bundled ? null : <span style={{ color: "red" }}>*</span>}
                  </p>
                  <InputNumber
                    style={{ width: "98%" }}
                    placeholder={i18n.t('addProduct:salePrice')}
                    type="number"
                    onChange={(value: any) => setprice(value * 100)}
                  />
                </div>
                {!bundled ? (
                  <div style={{ width: "100%" }}>
                    <p>{i18n.t('addProduct:regularPrice')}</p>
                    <InputNumber
                      style={{ width: "100%" }}
                      placeholder={i18n.t('addProduct:regularPrice')}
                      type="number"
                      onChange={(value: any) => setprevius_Price(value * 100)}
                    />
                  </div>
                ) : null}
              </div>
            ) : null}

            {idInLocal ? (
              <div className="btn_contents">
                <div style={{ width: "100%" }}>
                  <p>
                    {i18n.t('addProduct:localSalePrice')}{" "}
                    {bundled ? null : <span style={{ color: "red" }}>*</span>}
                  </p>
                  <div>
                  <InputNumber
                    style={{ width: "98%" }}
                    placeholder={i18n.t('addProduct:localSalePrice')}
                    type="number"
                    onChange={(value: any) => setpriceLocal(value * 100)}
                  />
                  <div style={{ width: "100%", marginTop: 10  }}>
                <p style={{margin: 0}}>{i18n.t('addProduct:priceLabel')}</p>
                <Select
                  defaultValue={label}
                  style={{ width:  "98%" }}
                  onChange={setlabel}
                  placeholder={i18n.t('addProduct:priceLabel')}
                  options={[
                    {
                      value: 'Ración',
                      label: 'Ración',
                    },
                    {
                      value: '1/2 Ración',
                      label: '1/2 Ración',
                    },
                    {
                      value: 'Unidad',
                      label: 'Unidad',
                    },

                    {
                      value: 'Copa',
                      label: 'Copa',
                    },

                    {
                      value: 'Botella',
                      label: 'Botella',
                    },

                    {
                      value: 'Botellin',
                      label: 'Botellin',
                    },

                    {
                      value: 'Lata',
                      label: 'Lata',
                    },
                  ]}
                />
              </div>
                  </div>
                </div>
                {!bundled ? (
                  <div style={{ width: "100%" }}>
                    <p>{i18n.t('addProduct:regularLocalPrice')}</p>
                    <div>
                    <InputNumber
                      style={{ width: "100%" }}
                      placeholder={i18n.t('addProduct:regularLocalPrice')}
                      type="number"
                      onChange={(value: any) => setprevius_PriceLocal(value * 100)}
                    />
                    <div style={{height: 64}}/>
                    </div>
                  </div>
                ) : null}
              </div>
            ) : null}


            {idInLocal ? (
              <div className="btn_contents">
                <div style={{ width: "100%" }}>
                  <p>
                    {i18n.t('addProduct:secondLocalSalePrice')}{" "}
                  </p>
                  <div>
                  <InputNumber
                    style={{ width: "98%" }}
                    placeholder={i18n.t('addProduct:secondLocalSalePrice')}
                    type="number"
                    onChange={(value: any) => setpriceLocal1(value * 100)}
                  />
                  <div style={{ width: "100%", marginTop: 10  }}>
                <p style={{margin: 0}}>{i18n.t('addProduct:priceTag')}</p>
                <Select
                  defaultValue={label1}
                  style={{ width:  "98%" }}
                  onChange={setlabel1}
                  placeholder={i18n.t('addProduct:priceTag')}
                  options={[
                    {
                      value: 'Ración',
                      label: 'Ración',
                    },
                    {
                      value: '1/2 Ración',
                      label: '1/2 Ración',
                    },
                    {
                      value: 'Unidad',
                      label: 'Unidad',
                    },

                    {
                      value: 'Copa',
                      label: 'Copa',
                    },

                    {
                      value: 'Botella',
                      label: 'Botella',
                    },
                    {
                      value: 'Botellin',
                      label: 'Botellin',
                    },

                    {
                      value: 'Lata',
                      label: 'Lata',
                    },
                  ]}
                />
              </div>
                  </div>
                </div>
                {!bundled ? (
                  <div style={{ width: "100%" }}>
                    <p>{i18n.t('addProduct:secondLocalRegularPrice')}</p>
                    <div>
                    <InputNumber
                      style={{ width: "100%" }}
                      placeholder={i18n.t('addProduct:secondRegularLocalPrice')}
                      type="number"
                      onChange={(value: any) => setprevius_PriceLocal1(value * 100)}
                    />
                    <div style={{height: 64}}/>
                    </div>
                  </div>
                ) : null}
              </div>
            ) : null}

            <div style={{ width: "100%", marginTop: 30 }}>
              <p style={{ margin: 0 }}>
                {i18n.t('addProduct:location')} <span style={{ color: "red" }}>*</span>
              </p>
              <Select
                mode="multiple"
                showArrow
                tagRender={tagRender}
                defaultValue={locationType}
                style={{ width: "100%" }}
                options={options}
                onChange={(value)=> {
                  setLocationType(value)
                  getAlergenos()
                }}
              />
            </div>

            {idInLocal ||idInDelivery ? (
              <div style={{ marginTop: 20 }}>
                <p style={{ margin: 0 }}>
                  {i18n.t('addProduct:allergens')}
                </p>
                <Alergenos data={alergenosBackEnd} alergenos={alergenos} setAlergenos={setAlergenos}/>
              </div>
            ) : null}

            {bundled ? (
              <div className="btn_contents">
                <div className="btn_item popu">
                  <p>{i18n.t('addProduct:recommended')}</p>
                  <Switch
                    defaultChecked={recomended}
                    onChange={onChangeRecomended}
                    className={recomended ? "popu" : "popu"}
                  />
                </div>
                <div className="btn_item new">
                  <p>{i18n.t('addProduct:outOfStock')}</p>
                  <Switch
                    defaultChecked={snoozed}
                    onChange={onChangeSnoozed}
                    className={snoozed ? "New" : "NoNew"}
                  />
                </div>
              </div>
            ) : (
              <div className="btn_contents">
                <div className="btn_item new">
                  <p>{i18n.t('addProduct:new')}</p>
                  <Switch
                    defaultChecked={isNew}
                    onChange={onChangeNew}
                    className={isNew ? "New" : "NoNew"}
                  />
                </div>
                <div className="btn_item offert">
                  <p>{i18n.t('addProduct:offer')}</p>
                  <Switch
                    defaultChecked={isOffert}
                    onChange={onChangeOffert}
                    className={isOffert ? "Off" : "NoOff"}
                  />
                </div>
                <div className="btn_item popu">
                  <p>{i18n.t('addProduct:popular')}</p>
                  <Switch
                    defaultChecked={isPopular}
                    onChange={onChangePopular}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
}
