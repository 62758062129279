import React, { useState } from "react";
import "./index.css";
import {
  TwitterOutlined,
  InstagramFilled,
  FacebookFilled,
  LinkedinFilled,
  TransactionOutlined,
  MailOutlined,
  RightOutlined,
  PhoneOutlined,
  EnvironmentOutlined,
  ShopOutlined,
  ShoppingOutlined,
} from "@ant-design/icons";
import { Select, Modal } from "antd";
import SimpleMap from "./Maps";
import { Link } from "react-router-dom";

import MainLogo from "../../Assets/images/icon.png";
import Android from "../../Assets/images/google.png";
import Apple from "../../Assets/images/apple.svg";

import i18n from "../../i18n";
import { PRIMARY_COLOR } from "../../Utils/Urls";

const { Option } = Select;

const Footer = () => {
  const [visible, setVisible] = useState(false);
  return (
    <>
      <div className="Footer">
        <div className="Footer-Content" style={{ justifyContent: "center" }}>
          <div>
            <img
              src={MainLogo}
              style={{ width: 60, marginBottom: 15, borderRadius: 15 }}
              alt=""
            />
            <div>
              <p className="text">{i18n.t("footer:increaseYourSales")}</p>
            </div>
            <div>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://play.google.com/store/apps/details?id=com.foodyapp"
              >
                <img
                  src={Android}
                  className="logos googl"
                  style={{ marginRight: 15 }}
                  alt=""
                />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://apps.apple.com/es/app/wilbby-comida-a-domicilio/id1553798083"
              >
                <img src={Apple} alt="" className="logos googl" />
              </a>
            </div>
            <div style={{ marginTop: 20 }}>
              <a
                href="https://twitter.com/wilbbyapp"
                rel="noreferrer"
                className="icons"
                target="_blank"
              >
                <TwitterOutlined style={{ fontSize: 22, marginRight: 15 }} />
              </a>
              <a
                href="https://www.facebook.com/wilbbyapp"
                rel="noreferrer"
                className="icons"
                target="_blank"
              >
                <FacebookFilled style={{ fontSize: 22, marginRight: 15 }} />
              </a>
              <a
                href="https://www.instagram.com/wilbbyapp"
                rel="noreferrer"
                className="icons"
                target="_blank"
              >
                <InstagramFilled style={{ fontSize: 22, marginRight: 15 }} />
              </a>
              <a
                href="https://www.linkedin.com/company/wilbbyapp/"
                rel="noreferrer"
                className="icons"
                target="_blank"
              >
                <LinkedinFilled style={{ fontSize: 22, marginRight: 15 }} />
              </a>
            </div>
          </div>

          <div className="SuBitemsxs">
            <ul>
              <li>
                <span className="SuBitemsspan">{i18n.t("footer:getHelp")}</span>
              </li>
              <li>
                <a
                  onClick={() => setVisible(true)}
                  style={{ cursor: "pointer" }}
                >
                  {i18n.t("footer:contact")}
                </a>
              </li>
              <li>
                <a href="https://wilbby.com/preguntas-frecuentes">
                  {i18n.t("footer:faq")}
                </a>
              </li>
              <li>
                <a href="https://wilbby.com/politica-de-privacidad">
                  {i18n.t("footer:privacyPolicy")}
                </a>
              </li>
              <li>
                <a href="https://wilbby.com/condiciones-de-uso">
                  {i18n.t("footer:useConditions")}
                </a>
              </li>
              <li>
                <a href="https://wilbby.com/cookies">
                  {i18n.t("footer:cookiesPolicy")}
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="Subfooter" />
        <div className="firma">
          <p style={{ color: "gray", marginRight: 20 }}>
            {" "}
            {"© 2021 "}
            {i18n.t("footer:madeWith")}
            {" 😍 "}
            {i18n.t("footer:in")}
            {" Burgos - Wilbby®"}
          </p>
        </div>
      </div>
      <Modal
        title="Contacto"
        open={visible}
        footer={false}
        onCancel={() => setVisible(false)}
      >
        <SimpleMap
          lat={42.3509557}
          lgn={-3.7657629}
          width="100%"
          height={200}
          title="Wilbby®"
        />
        <div style={{ margin: 20, paddingBottom: 30 }}>
          <div className="item_contact">
            <MailOutlined
              style={{ marginRight: 15, color: PRIMARY_COLOR, fontSize: 22 }}
            />{" "}
            <a
              href="mailto://info@Wilbby®app.es"
              rel="noreferrer"
              style={{ color: "black" }}
              target="_blank"
            >
              info@Wilbby.com
            </a>
            <RightOutlined style={{ marginLeft: "auto" }} />
          </div>
          <div className="item_contact">
            <PhoneOutlined
              style={{ marginRight: 15, color: PRIMARY_COLOR, fontSize: 22 }}
            />{" "}
            <a href="tel:+34640823527" style={{ color: "black" }}>
              {i18n.t("footer:talkWithUs")}
              {" Wilbby®"}
            </a>
            <RightOutlined style={{ marginLeft: "auto" }} />
          </div>
          <div className="item_contact">
            <EnvironmentOutlined
              style={{ marginRight: 15, color: PRIMARY_COLOR, fontSize: 22 }}
            />{" "}
            <Link to="/" style={{ color: "black" }}>
              Burgos ES
            </Link>
            <RightOutlined style={{ marginLeft: "auto" }} />
          </div>
          <div className="item_contact">
            <ShopOutlined
              style={{ marginRight: 15, color: PRIMARY_COLOR, fontSize: 22 }}
            />{" "}
            <a
              href="https://stores.wilbby.com/"
              style={{ color: "black" }}
              target="_black"
            >
              {i18n.t("footer:joinYourRestaurant")}
            </a>
            <RightOutlined style={{ marginLeft: "auto" }} />
          </div>
          <div className="item_contact">
            <ShoppingOutlined
              style={{ marginRight: 15, color: PRIMARY_COLOR, fontSize: 22 }}
            />{" "}
            <Link to="/riders" style={{ color: "black" }} target="_black">
              Reparte con nosotros
            </Link>
            <RightOutlined style={{ marginLeft: "auto" }} />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Footer;
