import React, { useState } from "react";
import "./index.css";
import {
  message,
  Modal,
  Input,
  Tooltip,
  Upload,
  Switch,
  Button,
  InputNumber,
} from "antd";
import { PlusCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { mutations } from "../../../GraphQL";
import { useMutation, Mutation } from "react-apollo";
import { DeleteOutlined } from "@ant-design/icons";
import { formaterPrice } from "../../../Utils/FormaterPrice";
import i18n from "../../../i18n";
import { PRIMARY_COLOR } from "../../../Utils/Urls";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export default function Eddit(props) {
  const { refetch, datas, visible, setVisible, setdataDetails, bundled } =
    props;

  const [title, settitle] = useState(datas.name[i18n.language]);
  const [price, setprice] = useState(0);
  const [imagen, setimagen] = useState(datas.imageUrl);
  const [loadingImage, setloadingImage] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [max, setmax] = useState(datas.max);
  const [min, setmin] = useState(datas.min);
  const [mulmax, setmulmax] = useState(datas.multiMax);
  const [mulmin, setmulmin] = useState(datas.multiply);
  const [recomended, setrecomended] = useState(datas.recomended);
  const [snoozed, setsnoozed] = useState(datas.snoozed);
  const domain = localStorage.getItem("domain");
  const [actualizarModified] = useMutation(mutations.UPDATE_MODIFIED);

  const uploadButton = (
    <Tooltip title={i18n.t("addProduct:addPhoto")}>
      <div>
        {loadingImage ? (
          <LoadingOutlined style={{ fontSize: 30, color: PRIMARY_COLOR }} />
        ) : (
          <PlusCircleOutlined style={{ fontSize: 30, color: PRIMARY_COLOR }} />
        )}

        <div className="ant-upload-text">
          <p>{i18n.t("addProduct:addPhoto")}</p>
        </div>
      </div>
    </Tooltip>
  );

  const newPrice = price * 100;

  const input = {
    _id: datas._id,
    name: title,
    description: datas.description[i18n.language],
    account: datas.account,
    location: datas.location,
    productType: datas.productType,
    plu: datas.plu,
    price: price > 0 ? Number(newPrice.toFixed(0)) : datas.price,
    sortOrder: datas.sortOrder,
    deliveryTax: datas.deliveryTax,
    takeawayTax: datas.takeawayTax,
    multiply: mulmin,
    multiMax: mulmax,
    posProductId: datas.posProductId,
    posProductCategoryId: datas.posProductCategoryId,
    subProducts: datas.subProducts,
    productTags: datas.productTags,
    posCategoryIds: datas.posCategoryIds,
    imageUrl: imagen,
    max: max,
    min: min,
    capacityUsages: datas.capacityUsages,
    parentId: datas.parentId,
    visible: true,
    snoozed: snoozed,
    subProductSortOrder: [],
    recomended: recomended,
    quantity: datas.quantity,
    new: true,
    popular: true,
    offert: true,
    storeId: datas.storeId,
  };

  const handleOk = () => {
    setConfirmLoading(true);
    actualizarModified({
      variables: {
        input: { data: input, domain: domain, language: i18n.language },
      },
    })
      .then((res) => {
        if (res.data.actualizarModified.success) {
          refetch();
          setVisible(false);
          setdataDetails(null);
          setConfirmLoading(false);
          message.success(i18n.t("addProduct:productSuccessfullyUpdated"));
        } else {
          message.warning(i18n.t("addProduct:somethingWentWrong"));
          setConfirmLoading(false);
          refetch();
        }
      })
      .catch(() => {
        refetch();
        message.error(i18n.t("addProduct:somethingWentWrong"));
        setConfirmLoading(false);
      });
  };

  const handleCancel = () => {
    setVisible(false);
    setdataDetails(null);
  };

  function onChangeRecomended(checked) {
    setrecomended(checked);
  }

  function onChangeSnoozed(checked) {
    setsnoozed(checked);
  }

  function onChangeMax(value) {
    setmax(value);
  }

  function onChangeMin(value) {
    setmin(value);
  }

  function onChangemulMax(value) {
    setmulmax(value);
  }

  function onChangemulMin(value) {
    setmulmin(value);
  }

  const isOk = () => {
    if (title) {
      return false;
    } else {
      return true;
    }
  };

  const onchangeTitle = (e) => {
    e.preventDefault();
    settitle(e.target.value);
  };

  return (
    <Modal
      title={i18n.t("addProduct:editProduct")}
      open={visible}
      onOk={handleOk}
      okText={i18n.t("addProduct:saveChanges")}
      cancelText={i18n.t("addProduct:cancel")}
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
      okButtonProps={{ disabled: isOk() }}
    >
      <div className="content_modal">
        <div className="headder">
          <div>
            <p>{i18n.t("addProduct:productPhoto")}</p>
            <Mutation mutation={mutations.UPLOAD_FILE_AWS}>
              {(singleUploadToAws) => (
                <Upload
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  customRequest={async (data) => {
                    setloadingImage(true);
                    let imgBlob = await getBase64(data.file);
                    singleUploadToAws({
                      variables: {
                        imgBlob,
                        domain: domain,
                        language: i18n.language,
                      },
                    })
                      .then((res) => {
                        setloadingImage(false);
                        setimagen(res.data.singleUploadToAws.data.Location);
                      })
                      .catch((error) => {
                        setloadingImage(false);
                        console.log("fs error: ", error);
                      });
                  }}
                >
                  {imagen ? (
                    <Tooltip title={i18n.t("addProduct:clickToChange")}>
                      <img className="imagen_prod_add" alt="" src={imagen} />
                    </Tooltip>
                  ) : null}

                  {!imagen ? uploadButton : null}
                </Upload>
              )}
            </Mutation>

            {imagen ? (
              <Button
                onClick={() => setimagen("")}
                icon={<DeleteOutlined />}
                type="primary"
                danger
                style={{ width: 200, marginTop: 10 }}
              >
                {i18n.t("addProduct:deleteImage")}
              </Button>
            ) : null}
            <br />

            <Button
              type="primary"
              style={{ width: 200, marginTop: 10 }}
              href="https://www.iloveimg.com/es/comprimir-imagen"
              target="_blank"
            >
              {i18n.t("addProduct:compressImage")}
            </Button>
          </div>
        </div>

        <div className="add_info">
          <h4>{i18n.t("addProduct:productInfo")}</h4>
          <p>
            {i18n.t("addProduct:modifierName")}{" "}
            <span style={{ color: "red" }}>*</span>
          </p>
          <Input
            placeholder={i18n.t("addProduct:productName")}
            onChange={onchangeTitle}
            defaultValue={title}
          />

          <div className="btn_contents">
            <div style={{ width: "100%" }}>
              <p>
                {i18n.t("addProduct:salePrice")}{" "}
                <span style={{ color: "red" }}>*</span>
              </p>
              <p>
                {formaterPrice(datas.price / 100, "", "")}{" "}
                {i18n.t("addProduct:actualPrice")}
              </p>
              <InputNumber
                style={{ width: bundled ? "48%" : "98%", marginTop: 8 }}
                placeholder={i18n.t("addProduct:newSalePrice")}
                min={0}
                type="number"
                onChange={(value) => setprice(value)}
              />
            </div>
          </div>

          <div className="btn_contents">
            <div>
              <p>{i18n.t("addProduct:minimum")}</p>
              <InputNumber
                min={0}
                max={100}
                style={{ width: "80%" }}
                placeholder={i18n.t("addProduct:minimum")}
                value={min}
                type="number"
                onChange={onChangeMin}
              />
            </div>
            <div>
              <p>{i18n.t("addProduct:maximum")}</p>
              <InputNumber
                min={1}
                max={100}
                style={{ width: "51%" }}
                placeholder={i18n.t("addProduct:maximum")}
                value={max}
                type="number"
                onChange={onChangeMax}
              />
            </div>
          </div>

          <div className="btn_contents">
            <div>
              <p>{i18n.t("addProduct:minimumMultiplicable")}</p>
              <InputNumber
                min={0}
                max={100}
                style={{ width: "49%" }}
                placeholder={i18n.t("addProduct:minimumMultiplicable")}
                value={mulmin}
                type="number"
                onChange={onChangemulMin}
              />
            </div>
            <div>
              <p>{i18n.t("addProduct:maximumMultiplicable")}</p>
              <InputNumber
                min={1}
                max={100}
                style={{ width: "51%" }}
                placeholder={i18n.t("addProduct:maximumMultiplicable")}
                value={mulmax}
                type="number"
                onChange={onChangemulMax}
              />
            </div>
          </div>

          <div className="btn_contents">
            <div className="btn_item popu">
              <p>{i18n.t("addProduct:recommended")}</p>
              <Switch
                defaultChecked={recomended}
                onChange={onChangeRecomended}
                className={recomended ? "popu" : "popu"}
              />
            </div>
            <div className="btn_item new">
              <p>{i18n.t("addProduct:outOfStock")}</p>
              <Switch
                defaultChecked={snoozed}
                onChange={onChangeSnoozed}
                className={snoozed ? "New" : "NoNew"}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
