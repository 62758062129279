import "./barners.css";
import Formulario from "../Form/form";
import i18n from '../../../i18n'

export default function Barners() {
  return (
    <div className="container_barner" id="unirme">
      <div className="container_barner_bg">
      <div className="container_barner_item">
      <div className="forms">
      <h1 className="titles">
            {i18n.t('landing:joinWilbbyForfree')}
          </h1>
          <p className="subtitles">
          {i18n.t('landing:increaseSales_2')}
          </p>
          <Formulario />
        </div>
        <div />
      </div>
      </div>
    </div>
  );
}
