import React from 'react';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Space, Modal, InputNumber, message } from 'antd';
import {useMutation} from "react-apollo";
import {mutations} from "../../../GraphQL";
import i18n from "../../../i18n";

export default function EditHour({visibleEdit, title, hours, setHours, setvisibleEdit, user, refetch}: any) {
  const [form] = Form.useForm();

  const [actualizarRestaurant] = useMutation(mutations.ACTUALIZAR_STORE)

  const handleCanceledit = () => {
    form.resetFields();
    setvisibleEdit(false);
    setHours(null)
  }


    const onFinish = (values: any) => {
      const myObjet = Object.assign(user.reservationScheduled, values);

      const input = {
        _id: user._id,
        reservationScheduled: myObjet,
      };

       actualizarRestaurant({ variables: { input: input } }).then((res) => {
        if (res.data.actualizarRestaurant.success) {
          form.resetFields();
          handleCanceledit();
          if(refetch) {
            refetch();
          }
          message.success(i18n.t("reservas:storeUpdatedSuccessfully"));
        } else {
          message.warning(i18n.t("reservas:somethingWentWrong"));
        }
      });
    };

    const objet = {
      [title]: hours
    }

   const initialValues = objet;

     
  return (
    <Modal
      title={title}
      open={visibleEdit}
      footer={false}
      onCancel={handleCanceledit}
    >
      <Form
        form={form}
        onFinish={onFinish}
        autoComplete="off"
        initialValues={initialValues}
      >
        <Form.List name={title}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Space
                  key={key}
                  style={{ display: "flex", marginBottom: 8, justifyContent: "center"}}
                  align="baseline"
                >
                  <Form.Item
                    {...restField}
                    name={[name, "star"]}
                    rules={[{ required: true, message: i18n.t("reservas:completeOpeningHour") }]}
                  >
                    <InputNumber placeholder={i18n.t("reservas:start")} style={{width: "100%"}} max={23} min={1} />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "end"]}
                    rules={[{ required: true, message: i18n.t("reservas:completeClosingHour") }]}
                  >
                    <InputNumber placeholder={i18n.t("reservas:end")} style={{width: "100%"}} max={23} min={1}/>
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                  style={{alignSelf: "center", marginLeft: "auto"}}
                >
                 {i18n.t("reservas:addSchedule")}
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <Form.Item>
          <Button type="primary" htmlType="submit" block>
            {i18n.t("reservas:saveChanges")}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
