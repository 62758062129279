import { useEffect } from "react";
import "./index.css";
import { Switch, message } from "antd";
import { BellOutlined } from "@ant-design/icons";
import { mutations } from "../../GraphQL";
import { useMutation } from "react-apollo";
import i18n from "../../i18n";
import LanguageSwitcher from "../LanguageSwitcher/LanguageSwitcher";

// const { Search } = Input;

export default function Header(props: any) {
  const { user, refetch } = props;
  const [actualizarRestaurant] = useMutation(mutations.ACTUALIZAR_STORE);

  // const suffix = (
  //   <AudioOutlined
  //     style={{
  //       fontSize: 16,
  //       color: PRIMARY_COLOR,
  //     }}
  //   />
  // );

  // const onSearch = (value: string) => console.log(value);

  const handleChange = (checked: boolean) => {
    const input = {
      _id: user._id,
      open: checked,
    };
    actualizarRestaurant({ variables: { input: input } }).then((res) => {
      if (res.data.actualizarRestaurant.success) {
        refetch();
        message.success(i18n.t("header:storeSuccessfullyUpdated"));
      } else {
        message.warning(i18n.t("header:somethingWentWrong"));
      }
    });
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://cdn.headwayapp.co/widget.js";
    document.head.appendChild(script);
    var config = {
      selector: ".headway-badge",
      account: "76mADx",
    };
    script.onload = function () {
      //@ts-ignore
      window.Headway.init(config);
    };
  }, []);

  return (
    <div className="header_cont">
      <div className="header_items">
        <div>
          <Switch
            checkedChildren={i18n.t("header:open")}
            unCheckedChildren={i18n.t("header:closed")}
            defaultChecked={user.open}
            onChange={handleChange}
          />
        </div>
        <div className="header_items">
          <div style={{ margin: "10px" }}>
            <LanguageSwitcher />
          </div>
          <button className="notification_btn">
            <BellOutlined style={{ fontSize: 20, color: "white" }} />
            <span
              // className="headway-badge"
              id="headway-badge"
              style={{ marginLeft: -17, marginTop: -8, position: "absolute" }}
            />
          </button>
        </div>
      </div>
    </div>
  );
}
