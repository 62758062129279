import React from "react";
import "./sesion.css";
import CreaditCard from "../../../Assets/images/Creadit-Card.svg";
import Location from "../../../Assets/images/Location.png";
import Offert from "../../../Assets/images/Offert.svg";
import i18n from "../../../i18n";


export default function Sesion() {
  return (
    <div className="sesion1" id="ventajas">
      <h1>{i18n.t('landing:whyWilbby')}</h1>
      <div className="sesion1__item">
        <div className="sesion1__item_cont">
          <div className="bg">
            <img src={CreaditCard} alt="Store Connect" />
          </div>
          <h3>{i18n.t('landing:increaseSales')}</h3>
          <p>
          {i18n.t('landing:increaseOrdersVolume')}
          </p>
        </div>
        <div className="sesion1__item_cont">
          <div className="bg">
          <img src={Location} alt="Store Connect" />
          </div>
          <h3>{i18n.t('landing:findNewCustomers')}</h3>
          <p>{i18n.t('landing:attractNewCustomers')}</p>
        </div>
        <div className="sesion1__item_cont">
          <div className="bg">
          <img src={Offert} alt="Store Connect" />
          </div>
          <h3>{i18n.t('landing:marketingAutomation')}</h3>
          <p>
          {i18n.t('landing:reduceCosts')}
          </p>
        </div>
      </div>
    </div>
  );
}
