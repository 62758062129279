import React, { useState } from "react";
import Products from "../Product";
import Bundled from "../Bundleds";
import Modificadores from "../Modifield";
import SearProduct from "../ProductSearch";
import "./index.css";
import { Select, Tag } from "antd";
import i18n from "../../../i18n";

const options = [{ value: "Delivery" }, { value: "Local" }];

export default function AllProductContent(props: any) {
  const { user } = props;
  const [active, setActive] = useState("1");
  const [locationType, setLocationType] = useState(["delivery", "Local"]);

  const tagRender = (props: any) => {
    const { label, closable, onClose } = props;
    const onPreventMouseDown = (event: any) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color="green"
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  };

  const renderItems = () => {
    if (active === "1") {
      return (
        <Products
          user={user}
          fromMenu={false}
          locationtype={["Delivery", "Local"]}
        />
      );
    } else if (active === "2") {
      return <Products user={user} fromMenu={false} locationtype={["Local"]} />;
    } else if (active === "3") {
      return <Bundled user={user} />;
    } else if (active === "4") {
      return <Modificadores user={user} />;
    } else if (active === "6") {
      return (
        <Products user={user} fromMenu={false} locationtype={["Delivery"]} />
      );
    } else {
      return (
        <>
          <Select
            mode="multiple"
            showArrow
            tagRender={tagRender}
            defaultValue={locationType}
            style={{ width: 250, marginBottom: 20 }}
            options={options}
            onChange={setLocationType}
          />
          <SearProduct user={user} locationType={locationType} />
        </>
      );
    }
  };

  return (
    <div>
      <div style={{ margin: "2rem 0" }} className="tabs_cont">
        <button
          onClick={() => setActive("1")}
          className={active === "1" ? "tabs_active" : "tabs_inactive"}
        >
          {i18n.t("addProduct:allProducts")}
        </button>
        <button
          onClick={() => setActive("2")}
          className={active === "2" ? "tabs_active" : "tabs_inactive"}
        >
          {i18n.t("addProduct:localProducts")}
        </button>
        <button
          onClick={() => setActive("6")}
          className={active === "6" ? "tabs_active" : "tabs_inactive"}
        >
          {i18n.t("addProduct:deliveryProducts")}
        </button>
        <button
          onClick={() => setActive("3")}
          className={active === "3" ? "tabs_active" : "tabs_inactive"}
        >
          {i18n.t("addProduct:mandatoryOptions")}
        </button>
        <button
          onClick={() => setActive("4")}
          className={active === "4" ? "tabs_active" : "tabs_inactive"}
        >
          {i18n.t("addProduct:optionalOptions")}
        </button>
        <button
          onClick={() => setActive("5")}
          className={active === "5" ? "tabs_active" : "tabs_inactive"}
        >
          {i18n.t("addProduct:searchProducts")}
        </button>
      </div>
      {renderItems()}
    </div>
  );
}
