import gql from "graphql-tag";

const AUTENTICAR_RESTAURANT = gql`
  mutation autenticarRestaurant($email: String, $password: String, $domain: String, $language: String) {
    autenticarRestaurant(email: $email, password: $password, domain: $domain, language: $language) {
      message
      success
      data {
        id
        token
        slug
      }
    }
  }
`;

const UPLOAD_FILE_AWS = gql`
  mutation singleUploadToAws($imgBlob: String, $domain: String, $language: String) {
    singleUploadToAws(file: $imgBlob, domain: $domain, language: $language) {
      data
    }
  }
`;

const NEW_ORDER_PROCEED = gql`
  mutation NewOrdenProceed(
    $ordenId: String
    $status: String
    $IntegerValue: Int
    $statusProcess: OrderProccessInput
    $domain: String
    $language: String
  ) {
    NewOrdenProceed(
      ordenId: $ordenId
      status: $status
      IntegerValue: $IntegerValue
      statusProcess: $statusProcess
      domain: $domain
      language: $language
    ) {
      messages
      success
      status
    }
  }
`;

const SNOOZED_PRODUCT = gql`
  mutation snoozedProduct($id: ID, $snoozed: Boolean) {
    snoozedProduct(id: $id, snoozed: $snoozed) {
      messages
      success
      status
    }
  }
`;

const CREATE_PRODUCT = gql`
  mutation createProduct($input: CreateProductInput) {
    createProduct(input: $input) {
      messages
      success
      status
    }
  }
`;

const DELETE_PRODUCT = gql`
  mutation eliminarProducto($id: ID) {
    eliminarProducto(id: $id) {
      messages
      success
      status
    }
  }
`;

const ACTUALIZAR_PRODUCT = gql`
  mutation actualizarProduct($input: JSON) {
    actualizarProduct(input: $input) {
      messages
      success
      status
    }
  }
`;

const ACTUALIZAR_BUNDLED = gql`
  mutation actualizarBundled($input: JSON) {
    actualizarBundled(input: $input) {
      messages
      success
      status
    }
  }
`;

const ADD_BUNDLED = gql`
  mutation createBundled($input: BundledInput) {
    createBundled(input: $input) {
      messages
      success
      status
    }
  }
`;

const ADD_MODIFIELD = gql`
  mutation createModifield($input: InputModifieldGroup) {
    createModifield(input: $input) {
      messages
      success
      status
    }
  }
`;

const ADD_MODIFIED_GROUP = gql`
  mutation createModifieldGroup($input: InputModifieldGroup) {
    createModifieldGroup(input: $input) {
      messages
      success
      status
    }
  }
`;

const UPDATE_MODIFIED_GROUP = gql`
  mutation actualizarModifiedGroup($input: JSON) {
    actualizarModifiedGroup(input: $input) {
      messages
      success
      status
    }
  }
`;

const UPDATE_MODIFIED = gql`
  mutation actualizarModified($input: JSON) {
    actualizarModified(input: $input) {
      messages
      success
      status
    }
  }
`;

const CREATE_CATEGORY = gql`
  mutation createNewCategory($input: JSON) {
    createNewCategory(input: $input) {
      messages
      success
      status
    }
  }
`;

const ELIMINAR_BUNDLED = gql`
  mutation eliminarBundled($id: ID) {
    eliminarBundled(id: $id) {
      messages
      success
      status
    }
  }
`;

const ACTUALIZAR_CATEGORY = gql`
  mutation actualizarCategoria($input: JSON) {
    actualizarCategoria(input: $input) {
      messages
      success
      status
    }
  }
`;

const UPDATE_CATEGORY = gql`
  mutation updateCategoria($input: JSON) {
    updateCategoria(input: $input) {
      messages
      success
      status
    }
  }
`;

const DELETE_MODIFIELDGROUP = gql`
  mutation eliminarmodifierGroups($id: ID) {
    eliminarmodifierGroups(id: $id) {
      messages
      success
      status
    }
  }
`;

const SNOOZED_MODIFIELD = gql`
  mutation snoozedModifield($id: ID, $snoozed: Boolean) {
    snoozedModifield(id: $id, snoozed: $snoozed) {
      messages
      success
      status
    }
  }
`;

const DELETE_MODIFIELD = gql`
  mutation eliminarModifield($id: ID) {
    eliminarModifield(id: $id) {
      messages
      success
      status
    }
  }
`;

const DELETE_CATEGORY = gql`
  mutation eliminarCategoria($id: ID) {
    eliminarCategoria(id: $id) {
      messages
      success
      status
    }
  }
`;

const UPLOAD_FILE_STORE = gql`
  mutation singleUploadToStoreImagenAws($file: String, $domain: String, $language: String) {
    singleUploadToStoreImagenAws(file: $file, domain: $domain, language: $language) {
      data
    }
  }
`;

const UPDATE_STORE = gql`
  mutation actualizarRestaurantAdmin($input: DataStore) {
    actualizarRestaurantAdmin(input: $input) {
      messages
      success
      status
    }
  }
`;

const ACTUALIZAR_STORE = gql`
  mutation actualizarRestaurant($input: RestaurantInput) {
    actualizarRestaurant(input: $input) {
      messages
      success
      status
    }
  }
`;

const UPDATE_RESERVA = gql`
  mutation NewReservaProceed($id: ID, $status: String, $domain: String) {
    NewReservaProceed(id: $id, status: $status, domain: $domain) {
      messages
      success
      status
    }
  }
`;

export const mutations = {
  UPDATE_STORE,
  UPLOAD_FILE_STORE,
  UPLOAD_FILE_AWS,
  AUTENTICAR_RESTAURANT,
  NEW_ORDER_PROCEED,
  ACTUALIZAR_STORE,
  SNOOZED_PRODUCT,
  CREATE_PRODUCT,
  DELETE_PRODUCT,
  ACTUALIZAR_PRODUCT,
  CREATE_CATEGORY,
  UPDATE_CATEGORY,
  DELETE_CATEGORY,
  ACTUALIZAR_BUNDLED,
  ELIMINAR_BUNDLED,
  ADD_BUNDLED,
  ADD_MODIFIED_GROUP,
  ADD_MODIFIELD,
  UPDATE_MODIFIED_GROUP,
  UPDATE_MODIFIED,
  DELETE_MODIFIELDGROUP,
  DELETE_MODIFIELD,
  SNOOZED_MODIFIELD,
  UPDATE_RESERVA,
  ACTUALIZAR_CATEGORY
};
