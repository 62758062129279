import { useEffect, useState } from "react";
import "./index.css";
import Logo from "../../Assets/images/logoblack.png";
import { Link } from "react-router-dom";
import Barra from "../../Components/BarraLeft";
import Header from "../../Components/Header";
import { Query, useQuery } from "react-apollo";
import { query } from "../../GraphQL";
import { Spin } from "antd";

//pages
import Memu from "../../Components/Menu";
import Order from "../../Components/Orders/order";
import Products from "../../Components/AddProduct/AllProductosContent";
import Tienda from "../../Components/Store/edit";
import Rating from "../../Components/Rating";
import Reservas from "../../Components/Reservas";
import i18n from "../../i18n";

export default function Dashboard() {
  const [active, setactive] = useState(1);
  const slug = localStorage.getItem("slug");
  const domain = localStorage.getItem("domain");

  const {
    data = {},
    loading,
    refetch,
    error,
  } = useQuery(query.GET_CONFIG_DOMAIN, {
    variables: { domain: domain },
  });

  const { getConfigDomain } = data;

  const datos = getConfigDomain ? getConfigDomain.data : null;

  useEffect(() => {
    const handleKosmoIntegration = () => {
      if (datos) {
        localStorage.setItem("isKosmo", JSON.stringify(datos.kosmoIntegration));
      }
    };
    handleKosmoIntegration();
  }, [datos]);

  return (
    <Query
      query={query.RESTAURANT}
      variables={{ slug: slug, domain: domain, language: i18n.language }}
    >
      {(response) => {
        if (response.loading || loading) {
          return (
            <div className="loading_cont">
              <Spin size="large" />
            </div>
          );
        }
        if (response) {
          const user =
            response &&
            response.data &&
            response.data.getEstablishmentForDetails
              ? response.data.getEstablishmentForDetails.data
              : {};
          response.refetch();

          return (
            <>
              <div className="cont_dashboar">
                <div className="cont_dashboar_menu">
                  <div className="logo_container">
                    <Link to="/dashboard">
                      <img src={Logo} alt="Wilbby" />
                    </Link>
                  </div>
                  <div className="menu_container">
                    <Barra
                      user={user}
                      active={active}
                      onClickOders={() => setactive(1)}
                      onClickStore={() => setactive(2)}
                      onClickMenu={() => setactive(3)}
                      onClickInHouse={() => setactive(7)}
                      onClickTransaction={() => setactive(4)}
                      onClickStart={() => setactive(5)}
                      onClickStatitis={() => setactive(6)}
                      onClickProduct={() => setactive(8)}
                    />
                  </div>
                  <div className="city_content">
                    <p>{user.city}</p>
                    {/* <DownOutlined style={{ color: PRIMARY_COLOR }} /> */}
                  </div>
                </div>
                <div className="cont_dashboar_content">
                  <Header user={user} refetch={response.refetch} />
                  <div className="content_full">
                    {active === 1 ? <Order user={user} /> : null}
                    {active === 2 ? (
                      <Tienda data={user} refetch={response.refetch} />
                    ) : null}
                    {active === 4 ? (
                      <Reservas user={user} refetch={response.refetch} />
                    ) : null}
                    {active === 3 ? (
                      <Memu user={user._id} inHouse={false} store={user} />
                    ) : null}
                    {active === 7 ? (
                      <Memu user={user._id} inHouse={true} store={user} />
                    ) : null}
                    {active === 5 ? <Rating user={user} /> : null}
                    {active === 8 ? <Products user={user._id} /> : null}
                  </div>
                </div>
              </div>
            </>
          );
        }
      }}
    </Query>
  );
}
