import React from 'react'
import "./index.css"
import i18n from "../../i18n";

export default function Alergenos({alergenos, setAlergenos, data}: any) {

    function removeItemFromArr(arr: any, item: any) {
        var i = arr.findIndex((x: any) => x._id === item._id);
        if (i !== -1) {
          arr.splice(i, 1);
          setAlergenos(alergenos.concat());
        }
      }
    
      const onSelectedItemsChange = (arr: any, item: any) => {
        if (alergenos.filter((e: any) => e._id === item._id).length > 0) {
          removeItemFromArr(arr, item);
          setAlergenos(alergenos.concat());
        } else {
            setAlergenos(alergenos.concat(item));
        }
      };

  
  return (
    <div>
        {data.map((item: any, i: number)=> {
            const ifExist = alergenos?.filter((e: any) => e._id === item._id).length > 0
            return <button key={i} className={ifExist ? 'elergens_active' : 'elergens'} onClick={()=> onSelectedItemsChange(alergenos, item)}> 
            <img src={item.image} alt={item.name[i18n.language]}/>
                {item.name[i18n.language]}
            </button>
        })}
    </div>
  )
}
