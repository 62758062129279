import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { ApolloProvider } from "react-apollo";
import ApolloClient, { InMemoryCache } from "apollo-boost";
import { RootSession } from "../src/App";
import { LOCAL_API_URL, LOCAL_API_PATH } from "./config";
import reportWebVitals from "./reportWebVitals";
import "./antd.css";
import "./i18n"

// Configuración del Apollo Client
const client = new ApolloClient({
  uri: LOCAL_API_URL + LOCAL_API_PATH,
  // enviar token al servidor
  fetchOptions: {
    credentials: "include",
  },
  request: (operation) => {
    const token = localStorage.getItem("token");
    operation.setContext({
      headers: {
        authorization: JSON.stringify(token),
      }
    });
  },
  cache: new InMemoryCache({
    addTypename: false,
  }),
  onError: () => {
    // if(graphQLErrors && graphQLErrors[0].extensions.code === 'UNAUTHENTICATED'){
    //   message.error('Debe iniciar sesión para agregar un servicio.');
    // }
  },
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <RootSession />
  </ApolloProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
