import React from "react";
import { Form, Input, Button, Checkbox, message } from "antd";
import { MailOutlined, LockOutlined, GlobalOutlined } from "@ant-design/icons";
import "./index.css";
import { useMutation } from "react-apollo";
import { mutations } from "../../GraphQL";
import { withRouter } from "react-router-dom";
import i18n from "../../i18n";
// import { DOMAINS } from "../../Utils/Urls";
const NormalLoginForm = (props: any) => {
  const { setView, history } = props;
  const [autenticarRestaurant] = useMutation(mutations.AUTENTICAR_RESTAURANT);

  const onFinish = (values: any) => {
    autenticarRestaurant({
      variables: {
        email: values.email,
        password: values.password,
        domain: values.domain,
        language: i18n.language,
      },
    })
      .then((res) => {
        if (res.data.autenticarRestaurant.success) {
          localStorage.setItem(
            "token",
            res.data.autenticarRestaurant.data.token
          );
          localStorage.setItem("id", res.data.autenticarRestaurant.data.id);
          localStorage.setItem("slug", res.data.autenticarRestaurant.data.slug);
          localStorage.setItem("domain", values.domain);
          message.success(res.data.autenticarRestaurant.message);
          history.push("/dashboard");
        } else {
          message.error(res.data.autenticarRestaurant.message);
        }
      })
      .catch(() => {
        message.success(i18n.t("login:somethingIsWrong"));
      });
  };

  return (
    <Form
      name="normal_login"
      className="login-form"
      initialValues={{ remember: true }}
      onFinish={onFinish}
    >
      <Form.Item
        name="email"
        rules={[{ required: true, message: i18n.t("login:enterEmail") }]}
      >
        <Input
          prefix={<MailOutlined className="site-form-item-icon" />}
          placeholder={i18n.t("login:email")}
          type="email"
        />
      </Form.Item>
      <Form.Item
        name="domain"
        rules={[{ required: true, message: i18n.t("login:enterDomain") }]}
      >
        <Input
          prefix={<GlobalOutlined className="site-form-item-icon" />}
          placeholder={i18n.t("login:domain")}
          type="text"
        />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[{ required: true, message: i18n.t("login:enterPassword") }]}
      >
        <Input.Password
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder={i18n.t("login:password")}
        />
      </Form.Item>
      <Form.Item>
        <Form.Item valuePropName="checked" noStyle>
          <Checkbox>{i18n.t("login:remember")}</Checkbox>
        </Form.Item>

        <Button type="link" onClick={() => setView("Forgot")}>
          {i18n.t("login:forgotPassword")}
        </Button>
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="login-form-button"
          shape="round"
          style={{ width: "100%" }}
        >
          {i18n.t("login:login")}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default withRouter(NormalLoginForm);
