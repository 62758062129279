import i18next from "i18next";

import {
  esAddProduct,
  esBarraLeft,
  esFooter,
  esHeader,
  esLanding,
  esLogin,
  esMenu,
  esNotFound,
  esOrders,
  esRating,
  esReservas,
  esStore,
} from "./Assets/locales/es";
import {
  enAddProduct,
  enBarraLeft,
  enFooter,
  enHeader,
  enLanding,
  enLogin,
  enMenu,
  enNotFound,
  enOrders,
  enRating,
  enReservas,
  enStore,
} from "./Assets/locales/en";
import {
  frAddProduct,
  frBarraLeft,
  frFooter,
  frHeader,
  frLanding,
  frLogin,
  frMenu,
  frNotFound,
  frOrders,
  frRating,
  frReservas,
  frStore,
} from "./Assets/locales/fr";
import {
  deAddProduct,
  deBarraLeft,
  deFooter,
  deHeader,
  deLanding,
  deLogin,
  deMenu,
  deNotFound,
  deOrders,
  deRating,
  deReservas,
  deStore,
} from "./Assets/locales/de";
import {
  itAddProduct,
  itBarraLeft,
  itFooter,
  itHeader,
  itLanding,
  itLogin,
  itMenu,
  itNotFound,
  itOrders,
  itRating,
  itReservas,
  itStore,
} from "./Assets/locales/it";

export const defaultNS = "notFound";
export const fallbackNS = "notFound";

  /**
   * In 'resources' we import the different translation .json.
   * To use this translation in the different files of our application, we use the following structure:
   * 
   * 1º We import i18n in the file we want to use the translation.
   * Example: import i18n from "../../i18n";
   * 
   * 2º We use the function t() of i18n to obtain the translation. 
   * Example: i18n.t("notFound:subTitle")
   * 
   * In this case, we are getting the translation of the key "title" from the file notFound.json.
   */

i18next.init({
  debug: false,
  fallbackLng: localStorage.getItem("language") || "es",
  defaultNS,
  fallbackNS,
  resources: {
    es: {
      addProduct:esAddProduct,
      barraLeft: esBarraLeft,
      footer: esFooter,
      header: esHeader,
      landing: esLanding,
      login: esLogin,
      menu: esMenu,
      notFound: esNotFound,
      orders: esOrders,
      rating: esRating,
      reservas: esReservas,
      store: esStore,
    },
    en: {
      addProduct:enAddProduct,
      barraLeft: enBarraLeft,
      footer: enFooter,
      header: enHeader,
      landing: enLanding,
      login: enLogin,
      menu: enMenu,
      notFound: enNotFound,
      orders: enOrders,
      rating: enRating,
      reservas: enReservas,
      store: enStore,
    },
    fr: {
      addProduct:frAddProduct,
      barraLeft: frBarraLeft,
      footer: frFooter,
      header: frHeader,
      landing: frLanding,
      login: frLogin,
      menu: frMenu,
      notFound: frNotFound,
      orders: frOrders,
      rating: frRating,
      reservas: frReservas,
      store: frStore,
    },
    de: {
      addProduct:deAddProduct,
      barraLeft: deBarraLeft,
      footer: deFooter,
      header: deHeader,
      landing: deLanding,
      login: deLogin,
      menu: deMenu,
      notFound: deNotFound,
      orders: deOrders,
      rating: deRating,
      reservas: deReservas,
      store: deStore,
    },
    it: {
      addProduct:itAddProduct,
      barraLeft: itBarraLeft,
      footer: itFooter,
      header: itHeader,
      landing: itLanding,
      login: itLogin,
      menu: itMenu,
      notFound: itNotFound,
      orders: itOrders,
      rating: itRating,
      reservas: itReservas,
      store: itStore,
    },
  }
});

export default i18next;
