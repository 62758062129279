import "./index.css";
import i18n from "../../../i18n";

export default function Items(props: any) {
  const { data, title, nota } = props;

  return (
    <div className="items-cart">
      <h3>{title}</h3>

      <div className="items-cart-horario">
        <p style={{ color: "gray" }}>
          {nota ? nota : i18n.t("orders:noOrderNote")}
        </p>
        <p style={{ color: "gray" }}>
          {data.Needcutlery ? i18n.t("orders:includeCutlery") : i18n.t("orders:notIncludeCutlery")}
        </p>
      </div>
    </div>
  );
}
