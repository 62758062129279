import { useState, useEffect } from "react";

export default function useScroll() {
  const [Scroll, setScroll] = useState(0);

  function myFunction() {
    setScroll(window.document.documentElement.scrollTop);
  }

  useEffect(() => {
    window.onscroll = function () {
      myFunction();
    };
  }, []);

  return { Scroll, setScroll };
}
