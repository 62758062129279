export const formaterPrice = (
  price: any,
  localeCode: string,
  currecy: string
) => {
  const getCurrency = ()=>{
    if(localStorage.getItem("domain") && localStorage.getItem("domain") === "blacksheeprestaurants.com"){
      return "HKD";
    } else {
      return "EUR";
    }
  }
  
  const priceFormater = new Intl.NumberFormat(
    localeCode ? localeCode : "es-ES",
    {
      style: "currency",
      currency: getCurrency(),
    }
  ).format(price);



  return priceFormater;
};
