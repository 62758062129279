import React from "react";
import "./sesion.css";
import Imagen1 from "../../../Assets/images/movil.png";
import Imagen2 from "../../../Assets/images/movil1.png";
import Apple from "../../../Assets/images/apple.svg";
import google from "../../../Assets/images/google.png";
import i18n  from "../../../i18n";

export default function Sesion() {
  return (
    <div className="sesion2">
      <h1 className="ti">{i18n.t('landing:discoverApp')}</h1>
      <div className="sesion2_items">
        <div>
          <img src={Imagen2} alt="Wilbby App" className="movil" />
        </div>

        <div>
          <h1>{i18n.t('landing:manageOrders')}</h1>
          <p>
          {i18n.t('landing:easyManagement')}
          </p>
        </div>

        <div>
          <h1>{i18n.t('landing:intuitiveProfile')}</h1>
          <p>
          {i18n.t('landing:manageProfile')}
          </p>
          <div className="apps">
            <a
              href="https://apps.apple.com/es/app/my-store-by-wilbby/id1546507971"
              target="_black"
            >
              <img src={Apple} alt="Apple store" target="_black" />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.wilbbystoreapp"
              target="_black"
            >
              <img src={google} alt="Play store" />
            </a>
          </div>
        </div>
        <div>
          <img src={Imagen1} alt="Wilbby App" className="movil" />
        </div>
      </div>
    </div>
  );
}
