import "./sesion.css";
import i18n from "../../../i18n";

export default function Sesion() {
  return (
    <div className="brand">
      <h1>{i18n.t('landing:collaboratingWithUs')}</h1>
      <p>
      {i18n.t('landing:storesTrustUs')}
      </p>
      <div className="brand_cont">
        <img src="https://header-store-wilbby.s3.eu-west-3.amazonaws.com/1653504709235-header-store-wilbby" alt="La Doña" />
        <img src="https://header-store-wilbby.s3.eu-west-3.amazonaws.com/1655316887714-header-store-wilbby" alt="Chesys" />
        <img src="https://header-store-wilbby.s3.eu-west-3.amazonaws.com/1652424802472-header-store-wilbby" alt="Marquinetti" />
        <img src="https://header-store-wilbby.s3.eu-west-3.amazonaws.com/1643729256851-header-store-wilbby" alt="La Cava Cervecería & Vinoteca" />
        <img src="https://header-store-wilbby.s3.eu-west-3.amazonaws.com/logo+1929.png" alt="Cervecería 1929" />
      </div>
    </div>
  );
}
