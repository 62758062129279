import React from "react";
import "./index.css";
import {
  ShoppingOutlined,
  ShopOutlined,
  AppstoreOutlined,
  CreditCardOutlined,
  BarChartOutlined,
  StarOutlined,
  QuestionCircleOutlined,
  LogoutOutlined,
  ExclamationCircleOutlined,
  TagOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import { Modal } from "antd";

import i18n from '../../i18n';

const { confirm } = Modal;

function Barra(props: any) {
  const {
    onClickOders,
    onClickStore,
    onClickMenu,
    onClickInHouse,
    onClickTransaction,
    onClickStart,
    onClickProduct,
    active,
    user,
    history,
  } = props;

  const LogOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("id");
    history.push("/login");
  };

  function showDeleteConfirm() {
    confirm({
      title: i18n.t('barraLeft:confirmDeleteTitle'),
      icon: <ExclamationCircleOutlined />,
      content: i18n.t('barraLeft:confirmDeleteContext'),
      okText: i18n.t('barraLeft:closeSession'),
      okType: "danger",
      cancelText: i18n.t('barraLeft:cancel'),
      onOk() {
        LogOut();
      },
      onCancel() {
        console.log(i18n.t('barraLeft:cancel'));
      },
    });
  }

  return (
    <div className="barra">
      <ul>
        <li className={active === 1 ? "menu_active" : "menu_inactive"}>
          <ShoppingOutlined />
          <button onClick={onClickOders}>{i18n.t('barraLeft:orders')}</button>
        </li>
        {user.aceptReservation ? (
          <li className={active === 4 ? "menu_active" : "menu_inactive"}>
            <CalendarOutlined />
            <button onClick={onClickTransaction}>{i18n.t('barraLeft:bookings')}</button>
          </li>
        ) : null}

        <li className={active === 2 ? "menu_active" : "menu_inactive"}>
          <ShopOutlined />
          <button onClick={onClickStore}>{i18n.t('barraLeft:store')}</button>
        </li>
        <li className={active === 8 ? "menu_active" : "menu_inactive"}>
          <TagOutlined />
          <button onClick={onClickProduct}>{i18n.t('barraLeft:products')}</button>
        </li>
        {user.inHouse ? (
          <li className={active === 7 ? "menu_active" : "menu_inactive"}>
            <AppstoreOutlined />
            <button onClick={onClickInHouse}>{i18n.t('barraLeft:localMenu')}</button>
          </li>
        ) : null}

        <li className={active === 3 ? "menu_active" : "menu_inactive"}>
          <AppstoreOutlined />
          <button onClick={onClickMenu}>{i18n.t('barraLeft:deliveryMenu')}</button>
        </li>

        <li className={active === 5 ? "menu_active" : "menu_inactive"}>
          <StarOutlined />
          <button onClick={onClickStart}>{i18n.t('barraLeft:ratings')}</button>
        </li>
        {/*  <li className={active === 6 ? "menu_active" : "menu_inactive"}>
          <BarChartOutlined />
          <button onClick={onClickStatitis}>Estadísticas</button>
        </li> */}
        {/* <li className={active === 7 ? "menu_active" : "menu_inactive"}>
          <QuestionCircleOutlined />
          <button onClick={onClickHelp}>Ayuda</button>
        </li> */}
        <li className="menu_inactive LogOut">
          <LogoutOutlined />
          <button onClick={showDeleteConfirm}>{i18n.t('barraLeft:closeSession')}</button>
        </li>
      </ul>
    </div>
  );
}

export default withRouter(Barra);
