import React from "react";
import "./sesion.css";
import Item1 from "../../../Assets/images/item.svg";
import Item2 from "../../../Assets/images/item1.svg";
import Item3 from "../../../Assets/images/item2.svg";
import { Link } from "react-router-dom";
import i18n from "../../../i18n";

export default function Sesion() {
  return (
    <div className="sesion4">
      <h1>{i18n.t('landing:howItWorks')}</h1>
      <div className="sesion4_cont">
        <div>
          <h3>{i18n.t('landing:joinYourStore')}</h3>
          <img src={Item1} alt="Wilbby" />
          <p>
          {i18n.t('landing:sendRequest')}
          </p>
        </div>
        <div>
          <h3>{i18n.t('landing:prepareYourBusiness')}</h3>
          <img src={Item2} alt="Wilbby" />
          <p>
          {i18n.t('landing:uploadYourProducts')}
          </p>
        </div>
        <div>
          <h3>{i18n.t('landing:startSelling')}</h3>
          <img src={Item3} alt="Wilbby" />
          <p>
          {i18n.t('landing:reachNewCustomers')}
          </p>
        </div>
      </div>
      <div className="liogon">
        <div>
          <h1>{i18n.t('landing:wilbbyPartners')}</h1>
          <p>
          {i18n.t('landing:startSelling')}
          </p>
        </div>
        <Link to="/login">
          {localStorage.getItem("id") ? i18n.t('landing:goDashboard') : i18n.t('landing:login')}
        </Link>
      </div>

      <div className="more">
        <h1>{i18n.t('landing:homeDelivery')}</h1>
        <p>
        {i18n.t('landing:digitizeStore')}
        </p>
      </div>
    </div>
  );
}
