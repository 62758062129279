import React, { useState } from "react";
import "./index.css";
import { message, Modal, Input, InputNumber, Button } from "antd";
import { mutations, query } from "../../../GraphQL";
import { useMutation, useQuery } from "react-apollo";
import AddProduct from "../add";
import { formaterPrice } from "../../../Utils/FormaterPrice";
import i18n from "../../../i18n";

export default function AddBundled(props) {
  const { refetch, visible, setVisible } = props;
  const [title, settitle] = useState("");
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [max, setmax] = useState(1);
  const [min, setmin] = useState(1);
  const [mulmax, setmulmax] = useState(1);
  const [mulmin, setmulmin] = useState(1);
  const [products, setproducts] = useState([]);

  const store = localStorage.getItem("id");
  const domain = localStorage.getItem("domain");

  const [createBundled] = useMutation(mutations.ADD_BUNDLED);

  const response = useQuery(query.GET_PRODUCT_BUNDLED, {
    variables: {
      store: localStorage.getItem("id"),
      domain: domain,
      language: i18n.language,
    },
  });

  const bundleds =
    response && response.data && response.data.getNewProductoBundled
      ? response.data.getNewProductoBundled.data
      : [];

  const deletedItem = (ids) => {
    var i = products.findIndex((x) => x === ids);
    if (i !== -1) {
      products.splice(i, 1);
      setproducts(products.concat());
    }
  };

  const SelectProduct = (ids) => {
    setproducts(products.concat(ids));
  };

  const input = {
    name: title,
    description: "",
    account: store,
    location: store,
    productType: 1,
    plu: "",
    price: 0,
    sortOrder: 0,
    deliveryTax: 0,
    takeawayTax: 0,
    multiply: mulmin,
    multiMax: mulmax,
    posProductId: "",
    posProductCategoryId: [],
    subProducts: products,
    productTags: [],
    posCategoryIds: [],
    imageUrl: null,
    max: max,
    min: min,
    capacityUsages: [],
    parentId: store,
    visible: true,
    snoozed: false,
    subProductSortOrder: [],
    recomended: false,
    quantity: 1,
    new: false,
    popular: false,
    offert: false,
    storeId: store,
  };

  const handleOk = () => {
    setConfirmLoading(true);
    createBundled({
      variables: {
        input: { data: input, domain: domain, language: i18n.language },
      },
    })
      .then((res) => {
        if (res.data.createBundled.success) {
          refetch();
          response.refetch();
          setVisible(false);
          setConfirmLoading(false);
          message.success(i18n.t("addProduct:productSuccessfullyAdded"));
        } else {
          message.warning(i18n.t("addProduct:somethingWentWrong"));
          setConfirmLoading(false);
          refetch();
          response.refetch();
        }
      })
      .catch(() => {
        refetch();
        message.error(i18n.t("addProduct:somethingWentWrong"));
        setConfirmLoading(false);
      });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  function onChangeMax(value) {
    setmax(value);
  }

  function onChangeMin(value) {
    console.log(value);
    setmin(value);
  }

  function onChangemulMax(value) {
    setmulmax(value);
  }

  function onChangemulMin(value) {
    setmulmin(value);
  }

  const isOk = () => {
    if (title) {
      return false;
    } else {
      return true;
    }
  };

  const onchangeTitle = (e) => {
    e.preventDefault();
    settitle(e.target.value);
  };

  return (
    <Modal
      title={i18n.t("addProduct:createOptions")}
      open={visible}
      onOk={handleOk}
      okText={i18n.t("addProduct:createOption")}
      cancelText={i18n.t("addProduct:cancel")}
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
      okButtonProps={{ disabled: isOk() }}
    >
      <div className="content_modal">
        <div className="add_info">
          <p>
            {i18n.t("addProduct:productName")}{" "}
            <span style={{ color: "red" }}>*</span>
          </p>
          <Input
            placeholder={i18n.t("addProduct:productName")}
            onChange={onchangeTitle}
            defaultValue={title}
          />
          <br />

          <div className="btn_contents">
            <div>
              <p>{i18n.t("addProduct:minimum")}</p>
              <InputNumber
                min={1}
                max={100}
                style={{ width: "80%" }}
                placeholder={i18n.t("addProduct:minimum")}
                value={min}
                type="number"
                onChange={onChangeMin}
              />
            </div>
            <div>
              <p>{i18n.t("addProduct:maximum")}</p>
              <InputNumber
                min={1}
                max={100}
                style={{ width: "80%" }}
                placeholder={i18n.t("addProduct:maximum")}
                value={max}
                type="number"
                onChange={onChangeMax}
              />
            </div>
          </div>

          <div className="btn_contents">
            <div>
              <p>{i18n.t("addProduct:minimumMultiplicable")}</p>
              <InputNumber
                min={1}
                max={100}
                style={{ width: "80%" }}
                placeholder={i18n.t("addProduct:minimumMultiplicable")}
                value={mulmin}
                type="number"
                onChange={onChangemulMin}
              />
            </div>
            <div>
              <p>{i18n.t("addProduct:maximumMultiplicable")}</p>
              <InputNumber
                min={1}
                max={100}
                style={{ width: "80%" }}
                placeholder={i18n.t("addProduct:maximumMultiplicable")}
                value={mulmax}
                type="number"
                onChange={onChangemulMax}
              />
            </div>
          </div>
        </div>

        <div style={{ marginTop: 20 }}>
          <h3>{i18n.t("addProduct:options")}</h3>
        </div>

        <AddProduct
          store={localStorage.getItem("id")}
          refetch={response.refetch}
          bundled={true}
        />

        <div>
          {bundleds &&
            bundleds.map((item, i) => {
              const adds =
                products && products.filter((x) => x === item._id).length > 0;

              return (
                <Button
                  onClick={() =>
                    adds ? deletedItem(item._id) : SelectProduct(item._id)
                  }
                  type={adds ? "primary" : "dashed"}
                  key={i}
                  style={{ marginRight: 10, marginTop: 10 }}
                >
                  {item.name[i18n.language]} (× {item.multiMax}){" "}
                  {item.price > 0
                    ? `  +${formaterPrice(item.price / 100, "", "")}`
                    : null}
                </Button>
              );
            })}
        </div>
      </div>
    </Modal>
  );
}
