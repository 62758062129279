import { useState, useEffect } from "react";
import i18n from "../../i18n";
import ReactFlagsSelect from "react-flags-select";
import "./LanguageSwitcher.css";

export default function LanguageSwitcher() {
  const [selected, setSelected] = useState(
    i18n.language === "en" ? "GB" : "ES"
  );

  useEffect(() => {
    const language = localStorage.getItem("language");
    if(language !== null && i18n.language !== language) {
      i18n.changeLanguage(language);
    }
  }, []);

  const showSelectedLabel = true;
  const showSecondarySelectedLabel = false;
  const showOptionLabel = true;
  const showSecondaryOptionLabel = false;
  const searchable = false;
  const placeholder = "";
  const customLabels = {
    GB: { primary: "EN", secondary: "+44" },
    ES: { primary: "ES", secondary: "+1" },
  };

  const onSelect = (code: string): void => {
    setSelected(code);
    const selectedCountry = code === "GB" ? customLabels.GB.primary.toLowerCase() : customLabels.ES.primary.toLowerCase();
    i18n.changeLanguage(selectedCountry);
    localStorage.setItem("language", i18n.language);
    window.location.reload();
  };

  return (
    <div className="demo-wrapper">
      <ReactFlagsSelect
        placeholder={placeholder}
        selected={selected}
        onSelect={onSelect}
        showSelectedLabel={showSelectedLabel}
        showSecondarySelectedLabel={showSecondarySelectedLabel}
        showOptionLabel={showOptionLabel}
        showSecondaryOptionLabel={showSecondaryOptionLabel}
        customLabels={customLabels}
        countries={["GB", "ES"]}
        searchable={searchable}
        selectButtonClassName="border_none"
      />
    </div>
  );
}
