import { useState } from "react";
import {
  Table,
  Tag,
  Space,
  Button,
  Avatar,
  Input,
  Tooltip,
  message,
  Modal,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { formaterPrice } from "../../Utils/FormaterPrice";
import { useQuery, useMutation } from "react-apollo";
import { query, mutations } from "../../GraphQL";
import EditProduct from "./EditProducto";
import "./index.css";
import i18n from "../../i18n";

const { Search } = Input;
const { confirm } = Modal;

export default function Products(props: any) {
  const [search, setsearch] = useState("");
  const [Loading, setLoading] = useState(false);
  const [visible, setvisible] = useState(false);
  const [dataDetails, setdataDetails] = useState(null);
  const [isd, setisd] = useState("");
  const [snoozedProduct] = useMutation(mutations.SNOOZED_PRODUCT);
  const { user, fromMenu, locationType } = props;
  const domain = localStorage.getItem("domain");
  const [eliminarProducto] = useMutation(mutations.DELETE_PRODUCT);
  const { data, refetch, loading } = useQuery(query.GET_PRODUCT_SEARCH, {
    variables: { store:user, search: search, locationType: locationType, domain: domain, language: i18n.language},
  });

  const onSearch = (value: string) => setsearch(value);

  const setDetalles = (prod: any) => {
    setdataDetails(prod);
    setvisible(true);
  };

  const eliminarProduct = (id: string) => {
    setisd(id);
    setLoading(true);
    eliminarProducto({ variables: { id: id } })
      .then((res) => {
        if (res.data.eliminarProducto.success) {
          setLoading(false);
          message.success(res.data.eliminarProducto.messages);
          refetch();
        } else {
          setLoading(false);
          message.warning(res.data.eliminarProducto.messages);
          refetch();
        }
      })
      .catch(() => {
        setLoading(false);
        message.error(i18n.t('addProduct:somethingWentWrong'));
      });
  };

  function showConfirm(id: string) {
    confirm({
      title: i18n.t('addProduct:confirmDeleteTitle'),
      icon: <ExclamationCircleOutlined />,
      content:
        i18n.t('addProduct:confirmDeleteContent'),
      okText: i18n.t('addProduct:deleteProduct'),
      cancelText: i18n.t('addProduct:cancel'),
      onOk() {
        eliminarProduct(id);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  }

  const products =
    data && data.getNewProductoSearchStore
      ? data.getNewProductoSearchStore.data
      : [];

  refetch();

  const snoozedProducts = (snoozed: boolean, id: string) => {
    snoozedProduct({ variables: { id: id, snoozed: snoozed } })
      .then((res) => {
        if (res.data.snoozedProduct.success) {
          message.success(i18n.t('addProduct:productMarkedAsOutOfStock'));
          refetch();
        } else {
          message.success(i18n.t('addProduct:somethingWentWrong'));
          refetch();
        }
      })
      .catch(() => {
        message.success(i18n.t('addProduct:somethingWentWrong'));
      });
  };

  const columns = [
    {
      key: "name",
      title: i18n.t('addProduct:name'),
      render: (data: any) => {
        return (
          <Space size="middle">
            <Avatar shape="square" size={50} src={data.imageUrl} />
            <h3 className="name_product">{data.name}</h3>
          </Space>
        );
      },
    },
    {
      key: "price",
      title: i18n.t('addProduct:price'),
      render: (data: any) => {
        return <p>{formaterPrice(data.price / 100, "", "")}</p>;
      },
    },
    {
      key: "description",
      title:i18n.t('addProduct:description'),
      render: (data: any) => {
        return <p className="name_product">{data.description}</p>;
      },
    },

    {
      key: "account",
      title:i18n.t('addProduct:availability'),
      render: (snoozed: any) =>
        snoozed.snoozed ? (
          <Tag color="red">{i18n.t('addProduct:outOfStock')}</Tag>
        ) : (
          <Tag color="lime">{i18n.t('addProduct:available')}</Tag>
        ),
    },
    {
      key: "_id",
      title:i18n.t('addProduct:action'),
      render: (data: any) => (
        <Space size="middle">
          <Tooltip title={i18n.t('addProduct:editProduct')}>
            <Button
              icon={<EditOutlined />}
              type="primary"
              onClick={() => setDetalles(data)}
            />
          </Tooltip>
          <Tooltip title={i18n.t('addProduct:deleteProduct')}>
            <Button
              danger
              icon={<DeleteOutlined />}
              onClick={() => showConfirm(data._id)}
              loading={isd === data._id && Loading}
            />
          </Tooltip>
          {data.snoozed ? (
            <Tooltip title={i18n.t('addProduct:notVisible')}>
              <Button
                type="dashed"
                icon={<EyeInvisibleOutlined />}
                onClick={() => snoozedProducts(false, data._id)}
              />
            </Tooltip>
          ) : (
            <Tooltip title={i18n.t('addProduct:visible')}>
              <Button
                type="dashed"
                icon={<EyeOutlined />}
                onClick={() => snoozedProducts(true, data._id)}
              />
            </Tooltip>
          )}
        </Space>
      ),
    },
  ];

  const productsTable = products.map((product: any) => {
    return {
      ...product,
      name: product.name[i18n.language],
      description: product.description[i18n.language],
    };
    })

  return (
    <div style={{ width: "100%" }}>
      <Space
        size="large"
        align="center"
        style={{ marginBottom: 20, marginLeft: fromMenu ? 20 : 0 }}
      >
        <Space>
          <Search
            placeholder={i18n.t('addProduct:searchProducts')}
            allowClear
            enterButton={i18n.t('addProduct:search')}
            size="large"
            onSearch={onSearch}
            style={{ marginLeft: "auto", width: 300 }}
          />
        </Space>
      </Space>
      <Table columns={columns} dataSource={productsTable} loading={loading} />
      {dataDetails ? (
        <EditProduct
          datas={dataDetails}
          refetch={refetch}
          visible={visible}
          setVisible={setvisible}
          user={user}
          setdataDetails={setdataDetails}
        />
      ) : null}
    </div>
  );
}
