import Logo from "../../../Assets/images/logowhite.png";
import LogoDark from "../../../Assets/images/logoblack.png";
import LogoSmall from "../../../Assets/images/icon.png";
import "./header.css";
import { Link } from "react-router-dom";
import i18n from "../../../i18n";
import useScroll from "../../../Hooks/Scroll";
import LanguageSwitcher from "../../../Components/LanguageSwitcher/LanguageSwitcher";


export default function Header({login}) {

  const { Scroll } = useScroll();

  return (
    <div className={Scroll > 50 ? "nav_shadow" : "nav"}>
      <div className="cont">
        <Link to="/">
          <img src={Scroll > 50 || login ? LogoDark : Logo} alt="Wilbby" className="logo" />
          <img src={LogoSmall} alt="Wilbby" className="logosmall" />
        </Link>
        <div className="cont_rigth">
          <ul>
            {localStorage.getItem("token") ? (
              <li>
                <Link to="/dashboard" className="btn_Login">
                {i18n.t("landing:goDashboard")}
                </Link>
              </li>
            ) : (
              <>
                <li className="links">
                  <a href="#unirme" className="btn_Login">
                  {i18n.t("landing:joinWilbby")}
                  </a>
                </li>
                <li>
                  <Link to="/login" className="btn_Login">
                  {i18n.t("landing:login")}
                  </Link>
                </li>
              </>
            )}
          </ul>
          <div style={{margin: "10px"}}></div>
          <LanguageSwitcher/>
        </div>
      </div>
    </div>
  );
}
