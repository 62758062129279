import "./landing.css";
import Header from "./header";
import Barner from "./Barners";
import Sesion from "./Seccion1";
import Sesion1 from "./sesion2";
import Sesion2 from "./sesion3";
import Sesion3 from "./sesion4";
import Footer from "../../Components/Footer";

export default function Landing() {
  return (
    <div className="landing">
      <Header />
      <Barner />
      <Sesion />
      <Sesion1 />
      <Sesion2 />
      <Sesion3 />
      <Footer />
    </div>
  );
}
