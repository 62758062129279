import { useState } from "react";
import {
  Table,
  Tag,
  Space,
  Button,
  Avatar,
  Tooltip,
  message,
  Modal,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { formaterPrice } from "../../Utils/FormaterPrice";
import { useQuery, useMutation } from "react-apollo";
import { query, mutations } from "../../GraphQL";
import AddProduct from "./AddModifield";
import EditProduct from "./EditProducto/EdditModifield";
import "./index.css";
import i18n from "../../i18n";

const { confirm } = Modal;

export default function Products(props: any) {
  const [Loading, setLoading] = useState(false);
  const [visible, setvisible] = useState(false);
  const [dataDetails, setdataDetails] = useState(null);
  const [isd, setisd] = useState("");
  const domain = localStorage.getItem("domain");
  const [snoozedModifield] = useMutation(mutations.SNOOZED_MODIFIELD);
  const { user, fromMenu } = props;
  const [eliminarModifield] = useMutation(mutations.DELETE_MODIFIELD);
  const { data, refetch, loading } = useQuery(query.GET_MODIFIELDS, {
    variables: { store:user, domain: domain, language: i18n.language},
  });

  const setDetalles = (prod: any) => {
    setdataDetails(prod);
    setvisible(true);
  };

  const eliminarProduct = (id: string) => {
    setisd(id);
    setLoading(true);
    eliminarModifield({ variables: { id: id } })
      .then((res) => {
        if (res.data.eliminarModifield.success) {
          setLoading(false);
          message.success(res.data.eliminarModifield.messages);
          refetch();
        } else {
          setLoading(false);
          message.warning(res.data.eliminarModifield.messages);
          refetch();
        }
      })
      .catch(() => {
        setLoading(false);
        message.error(i18n.t('addProduct:somethingWentWrong'));
      });
  };

  function showConfirm(id: string) {
    confirm({
      title: i18n.t('addProduct:confirmDeleteTitle2'),
      icon: <ExclamationCircleOutlined />,
      content:
        i18n.t('addProduct:confirmDeleteContent'),
      okText:  i18n.t('addProduct:deleteModifier'),
      cancelText: i18n.t('addProduct:cancel'),
      onOk() {
        eliminarProduct(id);
      },
      onCancel() {
        console.log(i18n.t('addProduct:cancel'));
      },
    });
  }

  const products =
    data && data.getNewProductoModifield
      ? data.getNewProductoModifield.data
      : [];

  refetch();

  const snoozedProducts = (snoozed: boolean, id: string) => {
    snoozedModifield({ variables: { id: id, snoozed: snoozed } })
      .then((res) => {
        if (res.data.snoozedModifield.success) {
          message.success( i18n.t('addProduct:productMarkedAsOutOfStock2'));
          refetch();
        } else {
          message.success(i18n.t('addProduct:somethingWentWrong'));
          refetch();
        }
      })
      .catch(() => {
        message.success(i18n.t('addProduct:somethingWentWrong'));
      });
  };

  const columns = [
    {
      key: "name",
      title: i18n.t('addProduct:name'),
      render: (data: any) => {
        return (
          <Space size="middle">
            <Avatar shape="square" size={50} src={data.imageUrl} />
            <h3 className="name_product">{data.name[i18n.language]}</h3>
          </Space>
        );
      },
    },
    {
      key: "price",
      title: i18n.t('addProduct:price'),
      render: (data: any) => {
        return <p>{formaterPrice(data.price / 100, "", "")}</p>;
      },
    },
    {
      key: "description",
      title: i18n.t('addProduct:description'),
      render: (data: any) => {
        return <p className="name_product">{data.description[i18n.language]}</p>;
      },
    },

    {
      key: "account",
      title:i18n.t('addProduct:availability'),
      render: (snoozed: any) =>
        snoozed.snoozed ? (
          <Tag color="red">{i18n.t('addProduct:outOfStock')}</Tag>
        ) : (
          <Tag color="lime">{i18n.t('addProduct:available')}</Tag>
        ),
    },
    {
      key: "_id",
      title: i18n.t('addProduct:action'),
      render: (data: any) => (
        <Space size="middle">
          <Tooltip title={i18n.t('addProduct:editModifier')}>
            <Button
              icon={<EditOutlined />}
              type="primary"
              onClick={() => setDetalles(data)}
            />
          </Tooltip>
          <Tooltip title={i18n.t('addProduct:deleteModifier')}>
            <Button
              danger
              icon={<DeleteOutlined />}
              onClick={() => showConfirm(data._id)}
              loading={isd === data._id && Loading}
            />
          </Tooltip>
          {data.snoozed ? (
            <Tooltip title={i18n.t('addProduct:notVisible')}>
              <Button
                type="dashed"
                icon={<EyeInvisibleOutlined />}
                onClick={() => snoozedProducts(false, data._id)}
              />
            </Tooltip>
          ) : (
            <Tooltip title={i18n.t('addProduct:visible')}>
              <Button
                type="dashed"
                icon={<EyeOutlined />}
                onClick={() => snoozedProducts(true, data._id)}
              />
            </Tooltip>
          )}
        </Space>
      ),
    },
  ];

  return (
    <div style={{ width: "100%" }}>
      <Space
        size="large"
        align="center"
        style={{ marginBottom: 20, marginLeft: fromMenu ? 20 : 0 }}
      >
        <Space size="middle">
          <AddProduct
            store={user}
            refetch={refetch}
            bundled={true}
            fromList={true}
          />
        </Space>
      </Space>
      <Table columns={columns} dataSource={products} loading={loading} />
      {dataDetails ? (
        <EditProduct
          datas={dataDetails}
          refetch={refetch}
          visible={visible}
          setVisible={setvisible}
          user={user}
          setdataDetails={setdataDetails}
          bundled={true}
        />
      ) : null}
    </div>
  );
}
