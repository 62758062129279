import { Result, Button } from "antd";
import { withRouter } from "react-router-dom";
import  i18n from "../../i18n";

function NoFound(props: any) {
  const { history } = props;

  return (
    <div>
      <Result
        status="404"
        title={i18n.t("notFound:title")}
        subTitle={i18n.t("notFound:subTitle")}
        extra={
          <Button type="primary" onClick={() => history.push("/")}>
            {i18n.t("notFound:backToHome")}
          </Button>
        }
      />
    </div>
  );
}

export default withRouter(NoFound);
