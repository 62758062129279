import { useQuery } from "react-apollo";
import { query } from "../../GraphQL";
import Categoty from "./category";
import i18n from "../../i18n";

export default function Menu(props: any) {
  const { user, inHouse, store } = props;
  const domain = localStorage.getItem("domain");
  

  const response = useQuery(query.NEW_MENU, { variables: { id: user, domain: domain, language: i18n.language } });

  const menu =
    response && response.data && response.data.getNewMenu
      ? response.data.getNewMenu.list
      : [];

  return (
    <div>
      <Categoty user={user} menu={menu[0]} inHouse={inHouse} store={store} />
    </div>
  );
}
