import React, { useState } from "react";
import "./index.css";
import { StarTwoTone } from "@ant-design/icons";
import Items from "./items";
import { useQuery } from "react-apollo";
import { query } from "../../GraphQL";
import { Pagination, Spin, Empty } from "antd";
import i18n from "../../i18n";
import { PRIMARY_COLOR } from "../../Utils/Urls";

export default function Rating({ user }: any) {
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  const domain = localStorage.getItem("domain");
  const {
    data = {},
    loading,
  } = useQuery(query.GET_VALORACION, {
    variables: {
      id: user._id,
      page: page,
      limit: limit,
      domain: domain,
      language: i18n.language,
    },
  });

  const { getValoracionesStore } = data;

  const rantings = getValoracionesStore ? getValoracionesStore.data : [];

  const renderItems = (item: any, id: number) => {
    return <Items item={item} key={id} />;
  };

  const onChangePage = (page: any, pageSize: any) => {
    setPage(page);
    setLimit(pageSize);
  };

  return (
    <div className="rating_cont">
      <div className="rating_cont_header">
        <h1>{i18n.t('rating:yourScoreInWilbby')}</h1>
        <h1>
          <StarTwoTone style={{ fontSize: 40 }} twoToneColor={PRIMARY_COLOR}/>
          {user.rating} {i18n.t('rating:outOf5')}
        </h1>
      </div>
      <div className="rating_cont_items">
        <h1>{i18n.t('rating:comments')}</h1>
        <div className="content">
          {loading ? (
            <div
              style={{
                width: "100%",
                height: 400,
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
                margin: "0 auto",
                display: "flex",
              }}
            >
              <Spin size="large" />
            </div>
          ) : (
            <>
              {rantings.length > 0 ? (
                <>
                  {rantings &&
                    rantings.map((item: any, i: number) => {
                      return renderItems(item, i);
                    })}
                </>
              ) : (
                <Empty description={i18n.t('rating:noScoresReceived')} />
              )}
            </>
          )}
        </div>
        <div
          style={{ width: "100%", justifyContent: "center", display: "flex" }}
        >
          <Pagination
            defaultCurrent={1}
            onChange={onChangePage}
            total={1000}
            pageSize={limit}
          />
        </div>
      </div>
    </div>
  );
}
