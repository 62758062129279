import "./index.css";
import { query } from "../../../GraphQL";
import { Query } from "react-apollo";
import Card from "./card";
import Add from "../addProduct";
import { Skeleton } from "antd";
import i18n from "../../../i18n";

export default function Products(props) {
  const { produts, user, titleCat, getData, dataCategory, inHouse } = props;
  const domain = localStorage.getItem("domain");

  return (
    <Query
      query={query.GET_NEW_PRODUCT_STORE}
      variables={{ products: produts, storeID: user._id, locationType: inHouse ? ["Local"] : ["Delivery"], domain: domain, language: i18n.language }}
    >
      {(response) => {
        if (response.loading) {
          return <Skeleton active />;
        }
        if (response) {
          const produts =
            response && response.data && response.data.getNewProductoForStore
              ? response.data.getNewProductoForStore.data
              : [];
          response.refetch();
          return (
            <div className="content_products">
              {produts &&
                produts.map((data, i) => {
                  return (
                    <div key={i}>
                      <Card
                        data={data}
                        refetch={response.refetch}
                        titleCat={titleCat}
                        dataCategory={dataCategory}
                        getData={getData}
                        inHouse={inHouse}
                      />
                    </div>
                  );
                })}
              <Add user={user} getData={getData} dataCategory={dataCategory} inHouse={inHouse} />
            </div>
          );
        }
      }}
    </Query>
  );
}
