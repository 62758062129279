import React from "react";
import { Form, Input, Button, message } from "antd";
import { MailOutlined } from "@ant-design/icons";
import "./index.css";
import { withRouter } from "react-router-dom";
import { LOCAL_API_URL } from "../../config";
import i18n from "../../i18n";

const NormalForgotForm = (props: any) => {
  const { setView } = props;
  const onFinish = async (values: any) => {
    let res = await fetch(
      `${LOCAL_API_URL}/forgotpassword-restaurant?email=${values.email}`
    );

    if (res) {
      const user = await res.json();
      if (!user.success) {
        message.error(user.message);
      } else {
        message.success(i18n.t("login:emailSent"));
        setView("Login");
      }
    }
  };

  return (
    <Form
      name="normal_login"
      className="login-form"
      initialValues={{ remember: true }}
      onFinish={onFinish}
    >
      <Form.Item
        name="email"
        rules={[{ required: true, message: i18n.t("login:enterEmail") }]}
      >
        <Input
          prefix={<MailOutlined className="site-form-item-icon" />}
          placeholder={i18n.t("login:email")}
          type="email"
        />
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="login-form-button"
          shape="round"
          style={{ width: "100%" }}
        >
          {i18n.t("login:sendLink")}
        </Button>
      </Form.Item>
      <Form.Item>
        <Button
          type="dashed"
          onClick={() => setView("Login")}
          shape="round"
          style={{ width: "100%" }}
        >
          {i18n.t("login:goBack")}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default withRouter(NormalForgotForm);
