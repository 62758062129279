import { useState } from "react";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Modal, message, Segmented, Input } from "antd";
import { useQuery, useMutation } from "react-apollo";
import { query, mutations } from "../../GraphQL";
import CardProducts from "./CardProducts";
import "./card.css";
import i18n from "../../i18n";
import { PRIMARY_COLOR } from "../../Utils/Urls";

const { Search } = Input;

export default function AddProduct(props: any) {
  const { user, getData, dataCategory, inHouse } = props;
  const [visible, setVisible] = useState(false);
  const [product, setproducts] = useState(dataCategory.products);
  const [updateCategoria] = useMutation(mutations.UPDATE_CATEGORY);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [search, setsearch] = useState("");
  const [active, setactive] = useState("Todos los productos");
  const domain = localStorage.getItem("domain");

  const response = useQuery(query.GET_PRODUCT, {
    variables: { store:user, locationType: inHouse ? ["Local"] : ["Delivery"], domain: domain, language: i18n.language  },
  });

  const products =
    response && response.data && response.data.getNewProductoaAllStore
      ? response.data.getNewProductoaAllStore.data
      : [];

  const { data, refetch, loading } = useQuery(query.GET_PRODUCT_SEARCH, {
    variables: { store:user, search: search, locationType: inHouse ? ["Local"] : ["Delivery"], domain: domain, language: i18n.language},
  });

  const productsSearch =
    data && data.getNewProductoSearchStore
      ? data.getNewProductoSearchStore.data
      : [];

  refetch();

  const SelectProduct = (ids: any) => {
    setproducts(product.concat(ids));
  };

  const onSearch = (value: string) => {
    setsearch(value);
  };

  const input = {
    _id: dataCategory._id,
    name: dataCategory.name[i18n.language],
    description: dataCategory.description[i18n.language],
    account: dataCategory.account,
    subCategories: [],
    imageUrl: dataCategory.imageUrl,
    products: product,
    menu:dataCategory.menu,
    sortedChannelProductIds: [],
    subProductSortOrder: [],
    subProducts: [],
    level: dataCategory.level,
    availabilities: [],
    internalId: dataCategory.internalId,
    storeId: dataCategory.storeId,
    sorting: dataCategory.sorting,
  };

  const handleOk = () => {
    setConfirmLoading(true);
    updateCategoria({ variables: { input: { data: input, domain: domain, language: i18n.language } } })
      .then((res) => {
        console.log()
        if (res.data.updateCategoria.success) {
          setConfirmLoading(false);
          message.success(i18n.t("menu:categoryUpdated"));
          getData();
        } else {
          setConfirmLoading(false);
          message.success(i18n.t('menu:somethingWentWrong'));
          getData();
        }
      })
      .catch(() => {
        setConfirmLoading(false);
        message.success(i18n.t('menu:somethingWentWrong'));
      });
  };

  return (
    <div>
      <div className="add_cont" onClick={() => setVisible(true)}>
        <PlusCircleOutlined style={{ fontSize: 36, color: PRIMARY_COLOR }} />
        <p>Añadir producto</p>
      </div>
      <Modal
        title={i18n.t('menu:addProduct')}
        open={visible}
        width={1000}
        onOk={handleOk}
        okText={i18n.t('menu:addProduct')}
        cancelText={i18n.t('menu:cancel')}
        confirmLoading={confirmLoading}
        onCancel={() => setVisible(false)}
        okButtonProps={{ disabled: false }}
      >
        <Segmented
          options={["Todos los productos", "Buscar productos"]}
          onChange={(value: any) => setactive(value)}
          defaultValue={active}
        />

        {active === "Todos los productos" ? (
          <div className="card_content_product">
            {products &&
              products.map((x: any, i: any) => {
                return (
                  <CardProducts
                    data={x}
                    key={i}
                    onclick={(values: any) => SelectProduct(values._id)}
                    product={product}
                    setproducts={setproducts}
                    getData={getData}
                    inHouse={inHouse}
                  />
                );
              })}
          </div>
        ) : (
          <div style={{ width: "100%", justifyContent: "center" }}>
            <Search
              placeholder={i18n.t('menu:searchProduct')}
              allowClear
              onSearch={onSearch}
              enterButton={i18n.t('menu:search')}
              style={{ width: "100%", marginTop: 20 }}
            />

            <div className="card_content_product" style={{ minHeight: 500 }}>
              {productsSearch &&
                productsSearch.map((x: any, i: any) => {
                  return (
                    <CardProducts
                      data={x}
                      key={i}
                      onclick={(values: any) => SelectProduct(values._id)}
                      product={product}
                      setproducts={setproducts}
                      getData={getData}
                      inHouse={inHouse}
                    />
                  );
                })}
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
}
