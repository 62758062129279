import React, { useState } from "react";
import "./index.css";
import {
  message,
  Modal,
  Input,
  Tooltip,
  Upload,
  Switch,
  Button,
  InputNumber,
} from "antd";
import { PlusCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { mutations } from "../../GraphQL";
import { useMutation, Mutation } from "react-apollo";
import { DeleteOutlined } from "@ant-design/icons";
import i18n from "../../i18n";
import { PRIMARY_COLOR } from "../../Utils/Urls";

function getBase64(file: any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export default function AddModifield(props: any) {
  const { store, refetch, bundled, fromList } = props;
  const [visible, setVisible] = useState(false);
  const [isNew, setisNew] = useState(false);
  const [isPopular, setisPopular] = useState(false);
  const [isOffert, setisOffert] = useState(false);
  const [title, settitle] = useState("");
  const [imagen, setimagen] = useState("");
  const [loadingImage, setloadingImage] = useState(false);
  const [price, setprice] = useState(0);
  const [max, setmax] = useState(1);
  const [min, setmin] = useState(1);
  const [mulmax, setmulmax] = useState(1);
  const [mulmin, setmulmin] = useState(1);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [recomended, setrecomended] = useState(false);
  const [snoozed, setsnoozed] = useState(false);
  const domain = localStorage.getItem("domain");

  const [createModifield] = useMutation(mutations.ADD_MODIFIELD);

  const uploadButton = (
    <Tooltip title="Añadir foto">
      <div style={{ height: 230, display: "grid", alignItems: "center" }}>
        <div className="ant-upload-text">
          {loadingImage ? (
            <LoadingOutlined style={{ fontSize: 30, color: PRIMARY_COLOR }} />
          ) : (
            <PlusCircleOutlined
              style={{ fontSize: 30, color: PRIMARY_COLOR }}
            />
          )}
          <p>{i18n.t("addProduct:addPhoto")}</p>
        </div>
      </div>
    </Tooltip>
  );

  const showModal = () => {
    setVisible(true);
  };

  const newPrice = price;

  const input = {
    name: title,
    description: "",
    account: store,
    location: store,
    productType: 1,
    plu: "",
    price: Number(newPrice.toFixed(0)),
    sortOrder: 0,
    deliveryTax: 0,
    takeawayTax: 0,
    multiply: mulmin,
    multiMax: mulmax,
    posProductId: "",
    posProductCategoryId: [],
    subProducts: [],
    productTags: [],
    posCategoryIds: [],
    imageUrl: imagen,
    max: max,
    min: min,
    capacityUsages: [],
    parentId: "",
    visible: true,
    snoozed: snoozed,
    subProductSortOrder: [],
    recomended: recomended,
    quantity: 1,
    new: isNew,
    popular: isPopular,
    offert: isOffert,
    previous_price: 0,
    storeId: store,
  };

  const handleOk = () => {
    setConfirmLoading(true);
    createModifield({
      variables: {
        input: { data: input, domain: domain, language: i18n.language },
      },
    })
      .then((res) => {
        if (res.data.createModifield.success) {
          message.success(i18n.t("addProduct:modifierSuccessfullyAdded"));
          refetch();
          setConfirmLoading(false);
          setVisible(false);
        } else {
          message.warning(i18n.t("addProduct:somethingWentWrong"));
          refetch();
          setConfirmLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
        message.error(i18n.t("addProduct:somethingWentWrong"));
        refetch();
        setConfirmLoading(false);
      });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  function onChangeRecomended(checked: boolean) {
    setrecomended(checked);
  }

  function onChangeSnoozed(checked: boolean) {
    setsnoozed(checked);
  }

  function onChangeMax(value: any) {
    setmax(value);
  }

  function onChangeMin(value: any) {
    setmin(value);
  }

  function onChangemulMax(value: any) {
    setmulmax(value);
  }

  function onChangemulMin(value: any) {
    setmulmin(value);
  }

  const isOk = () => {
    if (title) {
      return false;
    } else {
      return true;
    }
  };

  const onchangeTitle = (e: any) => {
    e.preventDefault();
    settitle(e.target.value);
  };

  return (
    <>
      <Button
        onClick={() => showModal()}
        type={bundled && !fromList ? "dashed" : "primary"}
        icon={<PlusCircleOutlined />}
        style={{
          height: bundled && !fromList ? 32 : 40,
          marginTop: bundled && !fromList ? 10 : 0,
        }}
      >
        {bundled
          ? i18n.t("addProduct:createModifier")
          : i18n.t("addProduct:addModifier")}
      </Button>

      <Modal
        title={i18n.t("addProduct:addModifier")}
        open={visible}
        onOk={handleOk}
        okText={i18n.t("addProduct:addModifier")}
        cancelText={i18n.t("addProduct:cancel")}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        okButtonProps={{ disabled: isOk() }}
      >
        <div className="content_modal">
          <p>{i18n.t("addProduct:modifierPhoto")}</p>
          <Mutation mutation={mutations.UPLOAD_FILE_AWS}>
            {(singleUploadToAws: any) => (
              <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                customRequest={async (data) => {
                  setloadingImage(true);
                  let imgBlob = await getBase64(data.file);
                  singleUploadToAws({
                    variables: {
                      imgBlob,
                      domain: domain,
                      language: i18n.language,
                    },
                  })
                    .then((res: any) => {
                      setloadingImage(false);
                      setimagen(res.data.singleUploadToAws.data.Location);
                    })
                    .catch((error: any) => {
                      setloadingImage(false);
                      message.warning(i18n.t("addProduct:imageTooLarge"));
                    });
                }}
              >
                {imagen ? (
                  <Tooltip title={i18n.t("addProduct:clickToChange")}>
                    <img className="imagen_prod_add" alt="" src={imagen} />
                  </Tooltip>
                ) : null}

                {!imagen ? uploadButton : null}
              </Upload>
            )}
          </Mutation>

          {imagen ? (
            <Button
              onClick={() => setimagen("")}
              icon={<DeleteOutlined />}
              type="primary"
              danger
              style={{ width: 200, marginTop: 10 }}
            >
              {i18n.t("addProduct:deleteImage")}
            </Button>
          ) : null}
          <br />

          <Button
            type="primary"
            style={{ width: 200, marginTop: 10 }}
            href="https://www.iloveimg.com/es/comprimir-imagen"
            target="_blank"
          >
            {i18n.t("addProduct:compressImage")}
          </Button>

          <div className="add_info">
            <h4>{i18n.t("addProduct:modifierInfo")}</h4>
            <p>
              {i18n.t("addProduct:modifierName")}{" "}
              <span style={{ color: "red" }}>*</span>
            </p>
            <Input
              placeholder={i18n.t("addProduct:modifierName")}
              onChange={onchangeTitle}
            />

            <div className="btn_contents">
              <div style={{ width: "100%" }}>
                <p>{i18n.t("addProduct:salePrice")}</p>
                <InputNumber
                  style={{ width: bundled ? "48%" : "98%", marginTop: 8 }}
                  placeholder={i18n.t("addProduct:newSalePrice")}
                  min={0}
                  type="number"
                  onChange={(value: any) => value && setprice(value * 100)}
                />
              </div>
            </div>

            <div className="btn_contents">
              <div>
                <p>{i18n.t("addProduct:minimum")}</p>
                <InputNumber
                  min={0}
                  max={100}
                  value={min}
                  style={{ width: "80%" }}
                  placeholder={i18n.t("addProduct:minimum")}
                  type="number"
                  onChange={onChangeMin}
                />
              </div>
              <div>
                <p>{i18n.t("addProduct:maximum")}</p>
                <InputNumber
                  min={1}
                  max={100}
                  value={max}
                  style={{ width: "80%" }}
                  placeholder={i18n.t("addProduct:maximum")}
                  type="number"
                  onChange={onChangeMax}
                />
              </div>
            </div>

            <div className="btn_contents">
              <div>
                <p>{i18n.t("addProduct:minimumMultiplicable")}</p>
                <InputNumber
                  min={0}
                  max={100}
                  value={mulmin}
                  style={{ width: "80%" }}
                  placeholder={i18n.t("addProduct:minimumMultiplicable")}
                  onChange={onChangemulMin}
                />
              </div>
              <div>
                <p>{i18n.t("addProduct:maximumMultiplicable")}</p>
                <InputNumber
                  min={1}
                  max={100}
                  value={mulmax}
                  type="number"
                  style={{ width: "80%" }}
                  placeholder={i18n.t("addProduct:maximumMultiplicable")}
                  onChange={onChangemulMax}
                />
              </div>
            </div>

            <div className="btn_contents">
              <div className="btn_item popu">
                <p>{i18n.t("addProduct:recommended")}</p>
                <Switch
                  defaultChecked={recomended}
                  onChange={onChangeRecomended}
                  className={recomended ? "popu" : "popu"}
                />
              </div>
              <div className="btn_item new">
                <p>{i18n.t("addProduct:outOfStock")}</p>
                <Switch
                  defaultChecked={snoozed}
                  onChange={onChangeSnoozed}
                  className={snoozed ? "New" : "NoNew"}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
