import React from "react";
import "./index.css";
import moment from "moment";
import { Button } from "antd";
import { ClockCircleOutlined } from "@ant-design/icons";
import i18n from "../../../i18n";

export default function Items(props: any) {
  const { data, title } = props;
  const domain = localStorage.getItem("domain");
  const timezoneOffset = domain === "blacksheeprestaurants.com" ? 480 : 120;

  return (
    <div className="items-cart">
      <div className="items-cart-horario">
        <h3>{i18n.t("orders:entryTime")}</h3>
        <p>{moment(data.created_at).utcOffset(timezoneOffset).format("lll")}</p>
      </div>

      {data.scheduled ? (
        <div className="items-cart-horario">
          <h3>{i18n.t("orders:scheduledOrder")}</h3>
          <Button
            type="primary"
            icon={<ClockCircleOutlined />}
            danger
            style={{ marginBottom: 10 }}
          >
            {moment(data.deliveryTime).utcOffset(timezoneOffset).format("lll")}
          </Button>
        </div>
      ) : (
        <div className="items-cart-horario">
          <h3>{title}</h3>
          <p>
            {moment(data.created_at)
              .utcOffset(timezoneOffset)
              .add(45, "minute")
              .format("lll")}
          </p>
        </div>
      )}

      <div className="items-cart-horario">
        <h3>{i18n.t("orders:orderType")}</h3>
        <p>
          {data.orderType === "delivery"
            ? i18n.t("orders:homeDelivery")
            : i18n.t("orders:pickUp")}
        </p>
      </div>
    </div>
  );
}
