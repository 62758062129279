import { useState } from "react";
import {
  message,
  Modal,
  Menu,
  Dropdown,
  Input,
  Switch,
  Tooltip,
  Upload,
} from "antd";
import { ExclamationCircleOutlined, MoreOutlined } from "@ant-design/icons";
import { mutations } from "../../GraphQL";
import { useMutation, Mutation } from "react-apollo";
import { PlusCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import Tags from "./tag";
import i18n from "../../i18n";
import { PRIMARY_COLOR } from "../../Utils/Urls";

/*  */

const { confirm } = Modal;

const { TextArea } = Input;

function getBase64(file: any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export default function DeleteMenu(props: any) {
  const { getData, data } = props;
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [title, settitle] = useState(data.name[i18n.language]);
  const [description, setdescription] = useState(data.description[i18n.language]);
  const [actualizarCategoria] = useMutation(mutations.ACTUALIZAR_CATEGORY);
  const [updateCategoria] = useMutation(mutations.UPDATE_CATEGORY);
  const domain = localStorage.getItem("domain");
  
  const [eliminarCategoria] = useMutation(mutations.DELETE_CATEGORY);
  const [loadingImage, setloadingImage] = useState(false);
  const [image, setimagen] = useState(data.imageUrl);
  const [tags, setTags] = useState<any[]>(data.tagsOffert.map((t: any)=> t.name ? t.name : t));

  const deleteMenu = (ids: string) => {
    eliminarCategoria({ variables: { id: ids } })
      .then((res) => {
        if (res.data.eliminarCategoria.success) {
          setConfirmLoading(false);
          message.success(i18n.t('menu:categoryDeleted'));
          getData();
        } else {
          setConfirmLoading(false);
          message.success(i18n.t('menu:somethingWentWrong'));
          getData();
        }
      })
      .catch(() => {
        setConfirmLoading(false);
        message.success(i18n.t('menu:somethingWentWrong'));
      });
  };

  function showDeleteConfirm() {
    confirm({
      title: i18n.t('menu:confirmDeleteTitle'),
      icon: <ExclamationCircleOutlined />,
      content: i18n.t('menu:confirmDeleteContent') ,
      okText:i18n.t('menu:delete'),
      okType: "danger",
      cancelText:i18n.t('menu:cancel'),
      onOk() {
        deleteMenu(data._id);
      },
      onCancel() {
        console.log(i18n.t('menu:cancel'));
      },
    });
  }

  const showModal = () => {
    setVisible(true);
  };

  const input = {
    _id: data._id,
    name: title,
    description: description,
    account: data.account,
    subCategories: [],
    imageUrl: image,
    products: data.products,
    menu:data.menu,
    sortedChannelProductIds: [],
    subProductSortOrder: [],
    subProducts: [],
    level: data.level,
    availabilities: [],
    internalId: data.internalId,
    storeId: data.storeId,
    sorting: data.sorting,
    tagsOffert: tags.map((t)=> {
      return {
        name: t,
        color: "#ff7d00"
      }
    })
  };

  const handleOk = () => {
    setConfirmLoading(true);
    updateCategoria({ variables: { input: { data: input, domain: domain, language: i18n.language } } })
      .then((res) => {
        if (res.data.updateCategoria.success) {
          setConfirmLoading(false);
          message.success(i18n.t('menu:categoryUpdated'));
          getData();
        } else {
          setConfirmLoading(false);
          message.success(i18n.t('menu:somethingWentWrong'));
          getData();
        }
      })
      .catch(() => {
        setConfirmLoading(false);
        message.success(i18n.t('menu:somethingWentWrong'));
      });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const actualizarCategory = (id: any, snoozed: boolean) => {
    actualizarCategoria({
      variables: {
        input: {
          data: {
            _id: id,
            snoozed: snoozed,
          },
          domain: domain,
          language: i18n.language
        },
      },
    })
      .then((res) => {
        if (res.data.actualizarCategoria.success) {
          getData();
          message.success(i18n.t('menu:categoryUpdated'));
        } else {
          message.success(i18n.t('menu:somethingWentWrong'));
        }
      })
      .catch((e) => {
        getData();
        console.log(e);
        message.success(i18n.t('menu:somethingWentWrong'));
      })
      .finally(() => {
        getData();
      });
  };

  const menu = (
    <Menu>
      <Menu.Item key={0} onClick={showModal}>{i18n.t('menu:editCategory')}</Menu.Item>

      <Menu.Item key={1} danger onClick={showDeleteConfirm}>
        {i18n.t('menu:deleteCategory')}
      </Menu.Item>
    </Menu>
  );

  const uploadButton = (
    <Tooltip title="Añadir foto">
      <div style={{height: 230, display: "grid", alignItems: "center"}}>
        <div className="ant-upload-text">
        {loadingImage ? (
          <LoadingOutlined style={{ fontSize: 30, color: PRIMARY_COLOR }} />
        ) : (
          <PlusCircleOutlined style={{ fontSize: 30, color: PRIMARY_COLOR }} />
        )}
          <p>{i18n.t('menu:addPhoto')} <span style={{color: "red"}}>{"("}{i18n.t('menu:mandatory')}{")"}</span></p>
        </div>
      </div>
    </Tooltip>
  );

  return (
    <div className="rigth_cont">
      <Dropdown overlay={menu}>
        <MoreOutlined
          style={{
            marginLeft: "auto",
            fontSize: 22,
            cursor: "pointer",
          }}
          onClick={(e) => e.preventDefault()}
        />
      </Dropdown>
      <Modal
        title={i18n.t('menu:editMenuCategory')}
        open={visible}
        onOk={handleOk}
        okText={i18n.t('menu:saveChanges')}
        cancelText={i18n.t('menu:cancel')}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <div className="contents_modal">
          <Mutation mutation={mutations.UPLOAD_FILE_AWS}>
            {(singleUploadToAws: any) => (
              <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                customRequest={async (data) => {
                  setloadingImage(true);
                  let imgBlob = await getBase64(data.file);
                  singleUploadToAws({ variables: { imgBlob, domain: domain, language: i18n.language } })
                    .then((res: any) => {
                      setloadingImage(false);
                      setimagen(res.data.singleUploadToAws.data.Location);
                    })
                    .catch((error: any) => {
                      setloadingImage(false);
                      message.warning(
                        i18n.t('menu:imageTooLarge')
                      );
                    });
                }}
              >
                {image ? (
                  <Tooltip title={i18n.t('menu:clickToChange')}>
                    <img
                      className="imagen_prod_add_category_upd"
                      src={image}
                      alt=""
                    />
                  </Tooltip>
                ) : null}

                {!image ? uploadButton : null}
              </Upload>
            )}
          </Mutation>

          <div style={{ marginBottom: 20, marginTop: 20 }}>
            <Switch
              checkedChildren={i18n.t('menu:hideCategory')}
              unCheckedChildren={i18n.t('menu:hiddenCategory')}
              defaultChecked={data.snoozed}
              style={{ zIndex: 100 }}
              onChange={() => {
                actualizarCategory(data._id, data.snoozed ? false : true);
              }}
            />
          </div>
          <Input
            placeholder={i18n.t('menu:addName')}
            defaultValue={title}
            onChange={(e) => settitle(e.target.value)}
          />
          <br />
          <br />
          <TextArea
            rows={4}
            placeholder={i18n.t('menu:addDescription')}
            onChange={(e) => setdescription(e.target.value)}
            showCount
            defaultValue={description}
            maxLength={60}
          />
        </div>

        <div style={{ marginBottom: 20, marginTop: 20, marginLeft: 20, marginRight: 20 }}>
          <Tags tags={tags} setTags={setTags} />
        </div>

      </Modal>
    </div>
  );
}
