import { Avatar, Comment, Tooltip, Rate } from "antd";
import moment from "moment";
import i18n from "../../i18n";

export default function ItemStart({ item }: any) {
  return (
    <div className="item_coment">
      <Comment
        author={<p>{`${item.Usuario.name} ${item.Usuario.lastName}`}</p>}
        avatar={
          <Avatar
            src={
              !item.avatar || item.avatar === "defaultAvatar.png"
                ? "https://internal-image-wilbby.s3.eu-west-3.amazonaws.com/defaultAvatar.jpg"
                : item.avatar
            }
            alt="Han Solo"
          />
        }
        content={
          <>
            <Rate
              disabled
              defaultValue={item.value}
              style={{ marginTop: -50 }}
            />
            <p>{item.comment ? item.comment : i18n.t('rating:noCommentLeft')}</p>
          </>
        }
        datetime={
          <Tooltip
            title={moment(item.created_at).format("YYYY-MM-DD HH:mm:ss")}
          >
            <span>{moment(item.created_at).fromNow()}</span>
          </Tooltip>
        }
      />
    </div>
  );
}
