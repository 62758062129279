import React from "react";
import "./index.css";
import { formaterPrice } from "../../../Utils/FormaterPrice";
import Modifield from "./Modifield";
import { Image } from "antd";
import i18n from "../../../i18n";

export default function Items(props: any) {
  const { title, data } = props;

  return (
    <div className="items-cart" style={{ marginLeft: 20, width: "100%" }}>
      <h3>{title}</h3>

      {data.items.map((product: any, i: number) => {
        const img = product.items.imageUrl
          ? product.items.imageUrl
          : "https://internal-image-wilbby.s3.eu-west-3.amazonaws.com/icon-green.png";
        return (
          <div key={i} className="product_item">
            <div className="product_quantity">
              <h3>{product.items.quantity} × </h3>
            </div>
            <div className="product_content">
              <div>
                <Image width={60} src={img} alt={product.items.name[i18n.language] ? product.items.name[i18n.language] : product.items.name} />
              </div>
              <div style={{ marginLeft: 15, paddingRight: 15 }}>
                <h1>{product.items.name[i18n.language] ? product.items.name[i18n.language] : product.items.name}</h1>
                <p style={{ fontSize: 11 }}>{product.items.description[i18n.language] ? product.items.description[i18n.language] : product.items.description[i18n.language]}</p>
                <Modifield
                  data={product.items.subItems}
                  loading={false}
                  language="es-ES"
                  currency="EUR"
                />
              </div>
              <div style={{ marginLeft: "auto" }}>
                <h1>
                  {formaterPrice(product.items.price / 100, "es-ES", "EUR")}
                </h1>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
